import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import Format from '~/lib/format';
import {LOCATION_TEXT} from '~/types/rob';

import sy from '~/styles';
import {Text, Pressable, Dialog, FormNumberInput} from '~/components/controls';

export default ({style, location, widths, onChange}) => {
  const [values, setValues] = useState(widths);
  const [showEdit, setShowEdit] = useState(null);

  useEffect(() => {
    setValues(widths);
  }, [widths]);

  return (
    <>
      <View style={[sy['pr-4'], style]}>
        <Pressable
          style={{
            width: 24,
            height: 24,
            backgroundColor: '#f2f2f2',
            borderRadius: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={() => setShowEdit(true)}>
          <Text style={sy.xSmallMedium}>
            {_.isNumber(widths?.[location]) ? (
              Format.number(widths?.[location], 1, false)
            ) : (
              <>?</>
            )}
          </Text>
        </Pressable>
      </View>
      {showEdit && (
        <Dialog
          visible={true}
          title="Bandprofiel"
          onDismiss={() => setShowEdit(false)}
          buttons={[
            {
              text: 'Annuleren',
              onPress: () => setShowEdit(false),
            },
            {
              text: 'OK',
              onPress: () => {
                onChange(values);
                setShowEdit(false);
              },
            },
          ]}>
          {Object.keys(widths).map((location) => (
            <View style={[sy['py-4']]} key={location}>
              <FormNumberInput
                label={`${LOCATION_TEXT[location] ?? location} (mm)`}
                value={widths[location]}
                digits={1}
                onChangeNumber={(val) =>
                  setValues({
                    ...values,
                    [location]: val,
                  })
                }
              />
            </View>
          ))}
        </Dialog>
      )}
    </>
  );
};
