import React, {useState, useCallback, useRef} from 'react';
import {View} from 'react-native';
import {Button} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {Text, Dialog} from '~/components/controls';
import Format from '~/lib/format';

import FileOpenIcon from '~/images/md-icons/file_open/materialicons/24px.svg';
import UploadFileIcon from '~/images/md-icons/upload_file/materialicons/24px.svg';

const InvoiceUpload = ({visible, lessor, onDismiss, onOK}) => {
  const fileRef = useRef(null);
  const dropZoneRef = useRef(null);
  const [pdf, setPdf] = useState(null);

  const handleFile = useCallback((e) => {
    e.persist();
    const fileList = e.target.files;

    if (fileList.length) {
      const pdfFile = fileList[0];

      if (pdfFile.type.indexOf('application/pdf') !== 0) {
        console.log('File is not an pdf file', pdfFile);
        return;
      }

      const reader = new FileReader();
      reader.onload = function (event) {
        setPdf({
          data: event.target.result,
          name: pdfFile.name,
          length: pdfFile.size,
        });
        e.target.value = '';
      };
      reader.readAsDataURL(pdfFile);
    }
  }, []);

  return (
    <Dialog
      visible={visible}
      title="Factuur uploaden"
      buttons={[
        {text: 'Terug', onPress: onDismiss},
        {
          text: 'Factureren',
          disabled: !pdf,
          textStyle: {color: '#972727'},
          onPress: async () => {
            await onOK(pdf.data);
          },
        },
      ]}
      options={{
        hideDividers: true,
        noPaddingContent: true,
      }}
      onDismiss={onDismiss}>
      <View style={[sy['px-6'], sy['py-4'], sy['gap-2']]}>
        <Text>
          Let op: facturatie aan {lessor}. Upload hier een PDF van de factuur.
        </Text>
        <View>
          {pdf ? (
            <View
              style={[
                sy['p-4'],
                sy['gap-2'],
                {
                  borderRadius: 8,
                  borderStyle: 'dotted',
                  borderWidth: 1,
                  borderColor: '#4a4a49',
                },
              ]}>
              <FileOpenIcon style={{alignSelf: 'center'}} fill="#4a4a49" />
              <Text style={[sy['truncate'], sy['text-center']]}>
                {pdf.name.slice(0, pdf.name.lastIndexOf('.'))}
              </Text>
              <Text style={[sy['text-lightgray'], sy['text-center']]}>
                {Format.number(pdf.length / 1024, 0)}
                &nbsp;kb&nbsp;&middot;&nbsp;pdf
              </Text>
            </View>
          ) : (
            <div
              ref={dropZoneRef}
              style={{
                padding: 16,
                borderRadius: 8,
                borderStyle: 'dotted',
                borderWidth: 1,
                borderColor: '#4a4a49',
              }}
              onDrop={(e) => {
                e.preventDefault();
                const items = e.dataTransfer.items;
                if (items) {
                  const item = items[0];

                  if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (file.type !== 'application/pdf') {
                      console.log('File is not an pdf file', file);
                      return;
                    }

                    const reader = new FileReader();
                    reader.onload = function (event) {
                      setPdf({
                        data: event.target.result,
                        name: file.name,
                        length: file.size,
                      });
                    };
                    reader.readAsDataURL(file);
                  }
                }
              }}
              onDragOver={(e) => {
                e.preventDefault();
                dropZoneRef.current.style.backgroundColor = '#f2f2f2';
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                dropZoneRef.current.style.backgroundColor = 'transparent';
              }}>
              <View style={[sy['items-center'], sy['gap-2']]}>
                <UploadFileIcon fill="#FF6C00" />
                <Text style={[sy['text-center'], {color: '#FF6C00'}]}>
                  Sleep hier heen
                  <br />
                  of
                </Text>
                <Button
                  style={[
                    {
                      borderColor: '#FF6C00',
                      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1.5px 5px 0.5px',
                    },
                  ]}
                  textColor="#FF6C00"
                  mode="outlined"
                  onPress={() => fileRef.current?.click()}>
                  Kies bestand
                </Button>
                <input
                  ref={fileRef}
                  style={{display: 'none'}}
                  type="file"
                  accept="application/pdf"
                  onInput={handleFile}
                />
              </View>
            </div>
          )}
        </View>
      </View>
    </Dialog>
  );
};

export default InvoiceUpload;
