import React, {useEffect, useState} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {Text, RadioButton, ActionButton} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import {rob as robApi} from '~/api/private';

const Reason = ({
  onDismiss,
  license,
  request_id,
  rob_code,
  operation_code,
  reason,
  onChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(reason);
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      if (rob_code) {
        const {result: rob_component} = await robApi.component(
          license,
          request_id,
          rob_code,
        );
        const selected_operation = rob_component[rob_code]?.operations.find(
          (item) => item.operation.code === operation_code,
        );

        if (selected_operation) {
          setReasons(selected_operation.reasons);
        }
      }

      setLoading(false);
    };

    fetch();
  }, [request_id, license, rob_code, operation_code]);

  const onOK = () => {
    onChange(selected);
  };

  return (
    <Actionsheet visible={true} onDismiss={onDismiss}>
      {loading && (
        <View style={[sy['p-4']]}>
          <ActivityIndicator size="large" color="#231fda" />
        </View>
      )}
      {!loading && (
        <>
          <Title>Reden</Title>
          <Divider />
          <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
            <ScrollView contentContainerStyle={{maxHeight: 400}}>
              {reasons.length === 0 && (
                <View style={[sy['p-4']]}>
                  <Text>Geen reden</Text>
                </View>
              )}
              {reasons.map((reason) => (
                <View
                  key={reason.code}
                  style={[sy['border-b'], sy['border-lightgray']]}>
                  <View style={[sy['p-4'], sy['flex-row'], sy['items-center']]}>
                    <View style={[sy['pr-4']]}>
                      <RadioButton
                        checked={selected === reason.code}
                        disabledTimeout={0}
                        onPress={() => {
                          setSelected(reason.code);
                        }}
                      />
                    </View>
                    <Text>{reason.description}</Text>
                  </View>
                </View>
              ))}
            </ScrollView>
          </Content>
          <Actions>
            <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
            <ActionButton onPress={onOK}>OK</ActionButton>
          </Actions>
        </>
      )}
    </Actionsheet>
  );
};

export default Reason;
