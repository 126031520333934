import _ from 'lodash';

export const ROB_STATUSES = {
  Draft: 'Draft',
  ApprovalRequested: 'ApprovalRequested',
  Approved: 'Approved',
  Denied: 'Denied',
  Invoiced: 'Invoiced',
  Archived: 'Archived',
  ProvisionallyApproved: 'ProvisionallyApproved',
};

export const ROB_TASK_STATUS = {
  Approved: 'Approved',
  Denied: 'Denied',
  CallLessor: 'CallLessor',
  LessorWillCall: 'LessorWillCall',
  Cancelled: 'Cancelled',
  CancellationApproved: 'CancellationApproved',
  CancellationDenied: 'CancellationDenied',
  Changed: 'Changed',
  ProvisionallyApproved: 'ProvisionallyApproved',
};

export const ROB_TASK_STATUS_LABELS = {
  [ROB_TASK_STATUS.Approved]: 'Akkoord',
  [ROB_TASK_STATUS.Denied]: 'Afgekeurd',
  [ROB_TASK_STATUS.CallLessor]: 'Bel LM',
  [ROB_TASK_STATUS.LessorWillCall]: 'LM belt',
  [ROB_TASK_STATUS.Cancelled]: 'Vervallen',
  [ROB_TASK_STATUS.CancellationApproved]: 'Vervallen akkoord',
  [ROB_TASK_STATUS.CancellationDenied]: 'Vervallen niet akkoord',
  [ROB_TASK_STATUS.ProvisionallyApproved]: 'Voorlopig akkoord',
};

export const ROB_NOT_INVOICED_STATUS_LABELS = {
  Invoiced: 'Al gefactureerd',
  WillNotBeInvoicedForWarrantyOrLeniencyReasons: 'Garantie of coulance',
  WillNotBeInvoiced: 'Niet gefactureerd',
};

export const ROB_LENIENCY_OPTIONS = {
  LeniencyRequested: 'LeniencyRequested',
  LeniencyResolved: 'LeniencyResolved',
  LeniencyDenied: 'LeniencyDenied',
};

export const isLessorStatus = (status) => {
  switch (status) {
    case ROB_STATUSES.ApprovalRequested:
      return true;
    default:
      break;
  }

  return false;
};

export const canInvoice = (status) => status === ROB_STATUSES.Approved;

export const isNotInvoiced = (status) => {
  switch (status) {
    case 'WillNotBeInvoicedForWarrantyOrLeniencyReasons':
    case 'WillNotBeInvoiced':
      return true;
    default:
      break;
  }

  return false;
};

export const isNotReadyForInvoicing = (status) =>
  status === 'NotReadyForInvoicing';

export const isInvoiced = (status) => status === ROB_STATUSES.Invoiced;
export const isArchived = (status) => status === ROB_STATUSES.Archived;

export const isTaskEditable = (status) => {
  switch (status) {
    case ROB_STATUSES.Approved:
      return false;
    default:
      break;
  }

  return true;
};

export const isRobTaskApproved = (status) => {
  switch (status) {
    case ROB_TASK_STATUS.Approved:
      return true;
    default:
      break;
  }

  return false;
};

export const isRobTaskDenied = (status) => {
  switch (status) {
    case ROB_TASK_STATUS.Denied:
    case ROB_TASK_STATUS.CallLessor:
    case ROB_TASK_STATUS.LessorWillCall:
      return true;
    default:
      break;
  }

  return false;
};

export const isRobTaskCancelled = (status) => {
  switch (status) {
    case ROB_TASK_STATUS.Cancelled:
    case ROB_TASK_STATUS.CancellationApproved:
    case ROB_TASK_STATUS.CancellationDenied:
      return true;
    default:
      break;
  }

  return false;
};

export const isRobTaskChanged = (status) => {
  switch (status) {
    case ROB_TASK_STATUS.Changed:
      return true;
    default:
      break;
  }

  return false;
};

export const isRobLeniencyTask = (status) => {
  switch (status) {
    case ROB_LENIENCY_OPTIONS.LeniencyRequested:
    case ROB_LENIENCY_OPTIONS.LeniencyResolved:
    case ROB_LENIENCY_OPTIONS.LeniencyDenied:
      return true;
    default:
      break;
  }

  return false;
};

export const ROB_TIRE_PURCHASE_CODE = '3196';
export const ROB_TIRE_REPAIR_CODE = '3197';
export const ROB_TIRE_REPLACE_CODE = '3198';
export const ROB_TIRE_SWAP_CODE = '3199';

export const ROB_TIRE_CODES = [
  ROB_TIRE_PURCHASE_CODE,
  ROB_TIRE_REPAIR_CODE,
  ROB_TIRE_SWAP_CODE,
  ROB_TIRE_REPLACE_CODE,
];

export const ROB_TIRE_EXCLUSIVE_CODES = [
  ROB_TIRE_PURCHASE_CODE,
  ROB_TIRE_SWAP_CODE,
  ROB_TIRE_REPLACE_CODE,
];

export const ROB_READY_CODES = {
  Ready: 'Ready',
  Invalid: 'Invalid',
  RobCode: 'RobCode',
  DurationAmount: 'DurationAmount',
  Parts: 'Parts',
  TaskAmount: 'TaskAmount',
  MountedTires: 'MountedTires',
  NewTiresProfileWidth: 'NewTiresProfileWidth',
  StoredTires: 'StoredTires',
};

export const ROB_READY_CODE_TEXTS = {
  Ready: 'Compleet',
  Invalid: 'Activiteit niet ingevuld',
  RobCode: 'ROB code niet ingevuld',
  DurationAmount: 'Duur en/of bedrag niet ingevuld',
  Parts: 'Onderdelen niet compleet',
  TaskAmount: 'Bedrag niet ingevuld',
  MountedTires: 'Gemonteerde banden niet ingevuld',
  NewTiresProfileWidth: 'Nieuwe banden profiel niet ingevuld',
  StoredTires: 'Opgeslagen banden niet ingevuld',
};

export const isRobTaskReady = (
  task,
  amount,
  component,
  rob_component,
  is_fleet_car,
  is_retail_car,
) => {
  if (isRobTaskCancelled(rob_component?.status.code)) {
    // Skip the checks if the rob task was cancelled.
    return ROB_READY_CODES.Ready;
  }

  if (!task || !task.id) {
    return ROB_READY_CODES.Invalid;
  }

  if (!is_fleet_car && !is_retail_car && !task.rob_code) {
    return ROB_READY_CODES.RobCode;
  }

  if (!_.isNumber(task.duration) && !_.isNumber(task.amount)) {
    return ROB_READY_CODES.DurationAmount;
  }

  if (
    (task.parts ?? []).every(
      (item) => _.isNumber(item.price) && _.isNumber(item.quantity),
    ) === false
  ) {
    return ROB_READY_CODES.Parts;
  }

  if (amount < 0 || (amount === 0 && task.warranty !== 'Warranty')) {
    return ROB_READY_CODES.TaskAmount;
  }

  if (ROB_TIRE_CODES.includes(task.rob_code)) {
    if (
      (task.parts ?? []).every(
        (item) =>
          _.isNumber(item.attributes?.mounted?.rob_tire_id) &&
          _.isNumber(item.attributes?.mounted?.profile_width),
      ) === false
    ) {
      return ROB_READY_CODES.MountedTires;
    }

    if (
      (task.parts ?? []).every(
        (item) =>
          !item.attributes?.new_rob_tire_id ||
          _.isNumber(item.attributes?.profile_width),
      ) === false
    ) {
      return ROB_READY_CODES.NewTiresProfileWidth;
    }

    if (
      (task.parts ?? []).every(
        (item) =>
          !item.attributes?.stored ||
          (_.isNumber(item.attributes.stored.rob_tire_id) &&
            _.isNumber(item.attributes.stored.profile_width)),
      ) === false
    ) {
      return ROB_READY_CODES.StoredTires;
    }
  }

  return ROB_READY_CODES.Ready;
};

export const isRobRequestReady = (
  components,
  rob,
  is_fleet_car,
  is_retail_car,
) => {
  if (components?.length === 0) {
    return false;
  }

  return components?.every((component) => {
    const main_task = component.tasks.find((task) => task.is_main_task);
    const task_amount = component.tasks.reduce(
      (acc, item) => acc + item.task_amount,
      0,
    );

    return (
      isRobTaskReady(
        main_task,
        task_amount,
        component,
        rob?.components[main_task?.id],
        is_fleet_car,
        is_retail_car,
      ) === ROB_READY_CODES.Ready
    );
  });
};

export const TIRE_PERMISSION_LABELS = {
  swap: 'wissel',
  replace: 'vervanging',
  repair: 'bewerking',
};

export const TIRE_PERMISSION_SORT = ['replace', 'swap', 'repair'];

export const TIRE_LOCATION_ORDER = ['LV', 'RV', 'LA', 'RA', 'X1'];

export const TIRE_SEASON_INDICATOR = {
  Summer: 'Summer',
  Winter: 'Winter',
  AllSeason: 'AllSeason',
};

export const TIRE_SWAP_SEASON_INDICATOR = {
  [TIRE_SEASON_INDICATOR.Summer]: [TIRE_SEASON_INDICATOR.Winter],
  [TIRE_SEASON_INDICATOR.Winter]: [TIRE_SEASON_INDICATOR.Summer],
  [TIRE_SEASON_INDICATOR.AllSeason]: [
    TIRE_SEASON_INDICATOR.Summer,
    TIRE_SEASON_INDICATOR.Winter,
  ],
};

export const TIRE_STATE = {
  Worn: 'Worn',
  Punctured: 'Punctured',
  Loud: 'Loud',
  Torn: 'Torn',
  DriedOut: 'DriedOut',
};

export const TIRE_STATE_LABEL = {
  [TIRE_STATE.Worn]: 'Versleten',
  [TIRE_STATE.Punctured]: 'Lek',
  [TIRE_STATE.Loud]: 'Luid',
  [TIRE_STATE.Torn]: 'Gescheurd',
  [TIRE_STATE.DriedOut]: 'Uitgedroogd',
};

export const TIRE_SOURCE = {
  New: 'New',
  Stored: 'Stored',
  Reserve: 'Reserve',
  Used: 'Used',
  LessorStock: 'LessorStock',
  ThirdParty: 'ThirdParty',
  Private: 'Private',
};

export const TIRE_SOURCE_LABEL = {
  [TIRE_SOURCE.New]: 'Nieuw',
  [TIRE_SOURCE.Stored]: 'Uit opslag',
  [TIRE_SOURCE.Reserve]: 'Reserveband',
  [TIRE_SOURCE.Used]: 'Gebruikt',
  [TIRE_SOURCE.LessorStock]: 'Uit voorraad LM',
  [TIRE_SOURCE.ThirdParty]: 'Via derde partij',
  [TIRE_SOURCE.Private]: 'Uit privé',
};

export const TIRE_REPAIR = {
  Repareren: 'Repareren',
  Balanceren: 'Balanceren',
  Wielomwisselen: 'Wielomwisselen',
};

export const LOCATION_TEXT = {
  L: 'Links',
  R: 'Rechts',
  M: 'Midden',
  V: 'Voor',
  A: 'Achter',
  B: 'Boven',
  O: 'Onder',

  // Tires
  LV: 'Linksvoor',
  RV: 'Rechtsvoor',
  LA: 'Linksachter',
  RA: 'Rechtsachter',
  X1: 'Reserve',
};

export const DEFAULT_LOCATIONS = ['L', 'R', 'M', 'V', 'A', 'B', 'O'];

export const ROB_LOCATIONS = {
  'V ': ['V'],
  'A ': ['A'],
  'R ': ['R'],
  'L ': ['L'],
  'M ': ['M'],
  LV: ['L', 'V'],
  RV: ['R', 'V'],
  LA: ['L', 'A'],
  RA: ['R', 'A'],
  LR: ['L', 'R'],
  LM: ['L', 'M'],
  RM: ['R', 'M'],
  LB: ['L', 'B'],
  LO: ['L', 'O'],
  RB: ['R', 'B'],
  RO: ['R', 'O'],
  MB: ['M', 'B'],
  MO: ['M', 'O'],
  BO: ['B', 'O'],
  'B ': ['B'],
  'O ': ['O'],
};

export const ROB_COSTS = {
  disposalFee: 'disposalFee',
  storageCosts: 'storageCosts',
  surchargeRunOnFlat: 'surchargeRunOnFlat',
};
export const ROB_COST_TEXTS = {
  [ROB_COSTS.disposalFee]: 'Verwijderingsbijdrage',
  [ROB_COSTS.storageCosts]: 'Opslagkosten',
  [ROB_COSTS.surchargeRunOnFlat]: 'Toeslag Run-On-Flat',
};

export const ROB_ERROR_TRANSLATIONS = {
  'ROB-SVC000137':
    'Aantal kilometers van gekozen onderhoudsactiviteit moet hoger zijn dan laatste onderhoudsactiviteit (zie historie).',
  'ROB-SVC000153':
    'Indienen voorstel niet mogelijk. Er is al een andere open aanvraag voor dit kenteken.',
  'ROB-SVC000062':
    'Indienen voorstel niet mogelijk. Afhandeling vereist van open aanvraag ouder dan 60 dagen.',
  'ROB-SVC000046':
    'Aantal gebruikte tekens in berichten te groot (maximaal 400 tekens toegestaan).',
  'ROB-SVC000061': 'Combinatie kenteken en meldcode niet juist.',
  'ROB-SVC000091':
    'Combinatie ROB activiteit, handeling en reden niet toegestaan.',
  'ROB-SVC000118':
    'Ingevoerd profiel niet toegestaan. Waarde moet tussen 0,1 en 20,0 zijn.',
  'ROB-SVC000067':
    'Geen wijzigingen mogelijk. Opdracht is mogelijk al in behandeling. Check de status of probeer het later nog eens.',
};
