export default {
  'text-lightgray': {
    color: '#828282',
  },
  'text-linkblue': {
    color: '#2907E3',
  },
  'text-white': {
    color: '#FFFFFF',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  overline: {
    textDecorationLine: 'overline',
  },
  'line-through': {
    textDecorationLine: 'line-through',
  },
  'no-underline': {
    textDecorationLine: 'none',
  },
  'normal-case': {
    textTransform: 'none',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  'text-right': {
    textAlign: 'right',
  },
  'text-center': {
    textAlign: 'center',
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  'text-ellipsis': {
    textOverflow: 'ellipsis',
  },
  'whitespace-nowrap': {
    whiteSpace: 'nowrap',
  },
};
