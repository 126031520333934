import React, {useState, useCallback, useRef, useEffect} from 'react';
import {View, ActivityIndicator, ScrollView, TextInput} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {
  Pressable,
  Text,
  RadioButton,
  Modal,
  Menu,
  Chip,
  TireTooltip,
} from '~/components/controls';
import Format from '~/lib/format';
import ArrowBackIcon from '~/images/md-icons/arrow_back/materialicons/24px.svg';
import {
  service_request as serviceRequestApi,
  rob as robApi,
} from '~/api/private';
import {TIRE_SEASON_INDICATOR} from '~/types/rob';

import AcUnitIcon from '~/images/md-icons/ac_unit/materialicons/24px.svg';
import SunnyIcon from '~/images/md-icons/sunny/materialicons/24px.svg';
import SunnySnowingIcon from '~/images/md-icons/sunny_snowing/materialicons/24px.svg';

const AddTireModal = ({
  license,
  request_id,
  rob_tire_id,
  initial_state,
  onDismiss,
  onChange,
  all_tires_allowed = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [selected, setSelected] = useState(null);
  const [sla, setSla] = useState({});
  const [lessor, setLessor] = useState(null);
  const [prefix, setPrefix] = useState('');
  const [allSeasonAllowed, setAllSeasonAllowed] = useState(all_tires_allowed);
  const [allSeasonRequired, setAllSeasonRequired] = useState(false);
  const [rofAllowed, setRofAllowed] = useState(all_tires_allowed);
  const [rofRequired, setRofRequired] = useState(false);

  const searchTextRef = useRef(null);
  const seasonIndicator = useRef(initial_state?.seasonIndicators);
  const preferredOnly = useRef(all_tires_allowed ? false : true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPrefixChange = useCallback(
    _.debounce(
      (value) => {
        if (value.length < 3) {
          setResults(null);
          return;
        }

        setLoading(true);

        let seasonIndicatorSelection =
          seasonIndicator.current?.length > 0
            ? seasonIndicator.current
            : Object.values(TIRE_SEASON_INDICATOR);

        serviceRequestApi
          .search_tires(request_id, value, {
            seasonIndicator: _.without(
              allSeasonRequired
                ? [TIRE_SEASON_INDICATOR.AllSeason]
                : seasonIndicatorSelection,
              allSeasonAllowed ? null : TIRE_SEASON_INDICATOR.AllSeason,
            ),
            tireCategory: rofRequired
              ? 'RunOnFlat'
              : !rofAllowed
              ? 'Standard'
              : null,
            makeCode: preferredOnly.current
              ? Object.keys(sla)
                  .filter((x) => sla[x].order)
                  .sort((a, b) => sla[a].order - sla[b].order)
              : null,
          })
          .then(({result, success}) => {
            if (success) {
              setResults(result);
              setLoading(false);
            }
          });
      },
      250,
      {leading: false, trailing: true},
    ),
    [sla, allSeasonRequired, rofRequired, allSeasonAllowed, rofAllowed],
  );

  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.sla(request_id);
      setLessor(result.lessor);
      const brands_sla =
        result?.tires.brands.reduce((acc, item) => {
          acc[item.makeCode] = {
            discount: item.discount,
            order: item.order,
          };
          return acc;
        }, {}) ?? {};
      setSla(brands_sla);

      let all_season_allowed = false;
      let all_season_required = false;
      let rof_required = false;
      let rof_allowed = false;
      if (all_tires_allowed) {
        setAllSeasonAllowed(true);
        setRofAllowed(true);
      } else {
        const {result: contract} = await robApi.contract(license, request_id);
        const allSeasonTiresInContract =
          contract?.leaseContract?.tires?.allSeasonTiresInContract;
        all_season_required = allSeasonTiresInContract === 'Required';
        setAllSeasonRequired(all_season_required);
        all_season_allowed = allSeasonTiresInContract !== 'NotAllowed';
        setAllSeasonAllowed(all_season_allowed);

        const rof_tires_in_contract =
          contract?.leaseContract?.tires?.rofTiresInContract;
        rof_required = rof_tires_in_contract === 'Required';
        setRofRequired(rof_required);
        rof_allowed = rof_tires_in_contract !== 'NotAllowed';
        setRofAllowed(rof_allowed);
      }

      try {
        if (rob_tire_id) {
          const {result: rob_tire} = await robApi.fetch_tire(
            request_id,
            rob_tire_id,
          );

          const {
            nominalWidthInMillimeters,
            widthHeightRatio,
            rimDiameterInInches,
            loadIndex,
            speedRating,
            make,
          } = rob_tire;

          const initial_prefix = `${nominalWidthInMillimeters} ${widthHeightRatio} ${rimDiameterInInches} ${loadIndex} ${speedRating} ${make}`;
          setPrefix(initial_prefix);

          const {result, success} = await serviceRequestApi.search_tires(
            request_id,
            initial_prefix,
            {
              seasonIndicator: _.without(
                all_season_required
                  ? [TIRE_SEASON_INDICATOR.AllSeason]
                  : initial_state?.seasonIndicators,
                all_season_allowed ? null : TIRE_SEASON_INDICATOR.AllSeason,
              ),
              tireCategory: rof_required
                ? 'RunOnFlat'
                : !rof_allowed
                ? 'Standard'
                : null,
              makeCode: Object.keys(brands_sla)
                .filter((x) => brands_sla[x].order)
                .sort((a, b) => brands_sla[a].order - brands_sla[b].order),
            },
          );

          if (success) {
            setResults(result);
          }
        }

        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    fetch();
  }, [request_id, rob_tire_id, license, initial_state, all_tires_allowed]);

  const onChangeSeasonIndicator = (value) => {
    if (seasonIndicator.current?.includes(value)) {
      seasonIndicator.current = seasonIndicator.current.filter(
        (item) => item !== value,
      );
    } else {
      seasonIndicator.current = [...(seasonIndicator.current ?? []), value];
    }

    onPrefixChange.cancel();
    onPrefixChange(prefix);
  };

  return (
    <Modal visible={true} onDismiss={onDismiss}>
      <View style={[{height: 64}, sy['flex-row'], sy['items-center']]}>
        <Pressable style={[sy['p-4'], sy['pr-8']]} onPress={() => onDismiss()}>
          <ArrowBackIcon />
        </Pressable>

        <TextInput
          ref={(ref) => {
            searchTextRef.current = ref;
            ref?.focus();
          }}
          value={prefix}
          style={[
            sy.regular,
            sy['flex-1'],
            {
              color: prefix?.length > 0 ? '#4A4A49' : '#828282',
            },
          ]}
          placeholder={'Zoek op merk, maat, type en/of naam'}
          onChangeText={(value) => {
            setPrefix(value);

            onPrefixChange.cancel();
            onPrefixChange(value);
          }}
        />
      </View>
      <Divider />
      <View style={[sy['flex-row'], sy['p-4'], sy['gap-2']]}>
        <Chip
          active={preferredOnly.current}
          onPress={() => {
            preferredOnly.current = !preferredOnly.current;

            onPrefixChange.cancel();
            onPrefixChange(prefix);
          }}>
          Voorkeur
        </Chip>
        {!allSeasonRequired && (
          <>
            <Chip
              active={seasonIndicator.current?.includes(
                TIRE_SEASON_INDICATOR.Summer,
              )}
              onPress={() =>
                onChangeSeasonIndicator(TIRE_SEASON_INDICATOR.Summer)
              }>
              Zomer
            </Chip>
            <Chip
              active={seasonIndicator.current?.includes(
                TIRE_SEASON_INDICATOR.Winter,
              )}
              onPress={() =>
                onChangeSeasonIndicator(TIRE_SEASON_INDICATOR.Winter)
              }>
              Winter
            </Chip>
          </>
        )}
        {allSeasonAllowed && (
          <Chip
            active={
              allSeasonRequired ||
              seasonIndicator.current?.includes(TIRE_SEASON_INDICATOR.AllSeason)
            }
            disabled={allSeasonRequired}
            onPress={() =>
              onChangeSeasonIndicator(TIRE_SEASON_INDICATOR.AllSeason)
            }>
            All-season
          </Chip>
        )}
      </View>
      <Divider />
      {loading && (
        <View style={[sy['flex-1'], sy['p-4']]}>
          <ActivityIndicator size="large" color="#231fda" />
        </View>
      )}
      {!loading && (
        <ScrollView>
          {results?.map((result, index) => (
            <View
              key={index}
              style={[
                sy['border-b'],
                sy['border-lightgray'],
                sy['flex-row'],
                sy['justify-between'],
                sy['p-4'],
              ]}>
              <Pressable
                style={[sy['flex-row'], sy['flex-1']]}
                onPress={() => {
                  setSelected(result);

                  onChange({
                    ...result,
                  });
                }}>
                <RadioButton
                  style={[
                    sy['justify-start'],
                    sy['pr-4'],
                    {height: 'auto', width: 'auto'},
                  ]}
                  checked={selected?.id === result.id}
                  disabledTimeout={0}
                />
                <View style={[sy['flex-1']]}>
                  <Text>
                    {result.make}&nbsp;{result.modelTypeProfile}&nbsp;
                    {result.typeNumber}
                  </Text>
                  <View style={[sy['flex-row'], sy['items-center']]}>
                    <Text style={[sy.small, sy['text-lightgray']]}>
                      {result.description}
                    </Text>
                  </View>
                </View>
              </Pressable>
              <View style={[sy['pl-2']]}>
                <Text>
                  {Format.price(
                    result.manufacturerSuggestedRetailPrice -
                      Math.round(
                        (1 - (sla[result.makeCode]?.discount ?? 1)) *
                          result.manufacturerSuggestedRetailPrice *
                          100,
                      ) /
                        100,
                  )}
                </Text>
                <View style={[sy['flex-row'], sy['justify-end']]}>
                  <TireTooltip
                    lessor={lessor}
                    {...result}
                    {...(sla[result.makeCode] ?? {})}
                    onDismiss={() => searchTextRef.current.focus()}
                  />
                  {result.seasonIndicator === 'Summer' && (
                    <SunnyIcon width={18} height={18} fill="#FFB800" />
                  )}
                  {result.seasonIndicator === 'Winter' && (
                    <AcUnitIcon width={18} height={18} fill="#49B3FF" />
                  )}
                  {result.seasonIndicator === 'AllSeason' && (
                    <SunnySnowingIcon width={18} height={18} fill="#997A29" />
                  )}
                </View>
              </View>
            </View>
          ))}
          {results?.length === 0 && (
            <Text style={[sy['p-4']]}>
              Geen banden gevonden.
              <br />
              <br />
              Controleer de invoer (alle eigenschappen scheiden met een spatie)
              en de filters.
            </Text>
          )}
        </ScrollView>
      )}
    </Modal>
  );
};

export default AddTireModal;
