import React, {useContext, useState, useEffect} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';
import moment from 'moment';
import _ from 'lodash';

import sy from '~/styles';
import UserContext from '~/components/context/UserContext';
import {
  DealerPersona,
  Text,
  Pressable,
  Dialog,
  ExpiredWarning,
  NotificationImportantIcon,
} from '~/components/controls';
import {useDispatch, useSelector} from '~/lib/hooks';
import {
  setShowDrawer,
  showRefreshDialog,
  showRefreshImportantDialog,
} from '~/actions';
import {service_request as serviceRequestApi} from '~/api/private';
import {EXPIRED_STATUS} from '~/types/statuses';
import {throttle} from '~/lib/utils';

import AccountCircleIcon from '~/images/md-icons/account_circle/materialicons/24px.svg';
import UpdateIcon from '~/images/md-icons/update/materialicons/24px.svg';

const doFetchExpired = throttle(async () => {
  const {result: expired} = await serviceRequestApi.expired();
  return expired;
});

const doFetchImportantNotification = throttle(async () => {
  const result = await serviceRequestApi.fetch_important_notification();
  return result;
});

const DealerHeader = (props) => {
  const dispatch = useDispatch();
  const {me} = useContext(UserContext);

  const update_important_message = useSelector(
    (state) => state.update_important_message,
  );

  const refresh_required = useSelector((state) => state.refresh_required);

  const urgent_refresh_required = useSelector(
    (state) => state.urgent_refresh_required,
  );

  const [importantNotification, setImportantNotification] = useState(null);
  const [showImportantNotification, setShowImportantNotification] =
    useState(false);
  const [expired, setExpired] = useState(null);

  useEffect(() => {
    doFetchImportantNotification().then(setImportantNotification);
  }, [update_important_message]);

  useEffect(() => {
    doFetchExpired().then(setExpired);
  }, [me]);

  let title = props.title;
  if (!title) {
    title =
      me?.dealer_selection.length === 1
        ? me?.dealers.find((dealer) => dealer.id === me?.dealer_selection[0])
            ?.city
        : me?.dealers.length === me?.dealer_selection?.length
        ? 'Alle vestigingen'
        : 'Geen vestiging';
  }

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Pressable onPress={() => dispatch(setShowDrawer(true))}>
          {me?.dealer_selection?.length === 1 ? (
            <DealerPersona dealer_id={me?.dealer_selection[0]} />
          ) : (
            <AccountCircleIcon fill="#231fda" width={36} height={36} />
          )}
        </Pressable>

        <Text
          style={[
            sy.xxLarge,
            sy['flex-1'],
            {marginLeft: 20},
            props.titleStyle,
          ]}>
          {title}
        </Text>

        {urgent_refresh_required ? (
          <Pressable onPress={() => dispatch(showRefreshImportantDialog())}>
            <UpdateIcon fill="#2907E3" width="24" height="24" />
          </Pressable>
        ) : refresh_required ? (
          <Pressable onPress={() => dispatch(showRefreshDialog())}>
            <UpdateIcon fill="#2907E3" width="24" height="24" />
          </Pressable>
        ) : importantNotification?.message?.length > 0 ? (
          <Pressable onPress={() => setShowImportantNotification(true)}>
            <NotificationImportantIcon {...importantNotification} />
          </Pressable>
        ) : expired?.[EXPIRED_STATUS.EXPIRED_60_DAYS] > 0 ? (
          <ExpiredWarning
            type={EXPIRED_STATUS.EXPIRED_60_DAYS}
            count={expired?.[EXPIRED_STATUS.EXPIRED_60_DAYS]}
          />
        ) : expired?.[EXPIRED_STATUS.EXPIRED_30_DAYS] > 0 ? (
          <ExpiredWarning
            type={EXPIRED_STATUS.EXPIRED_30_DAYS}
            count={expired?.[EXPIRED_STATUS.EXPIRED_30_DAYS]}
          />
        ) : null}
      </View>
      <Dialog
        visible={showImportantNotification}
        title={importantNotification?.title ?? 'Belangrijk bericht'}
        titleIcon={<NotificationImportantIcon {...importantNotification} />}
        onDismiss={() => setShowImportantNotification(false)}
        options={{
          noPaddingContent: true,
        }}>
        <View style={[sy['py-4'], sy['px-6']]}>
          <Text>{importantNotification?.message}</Text>
        </View>
        <Divider />
        <View style={[sy['py-4'], sy['px-6']]}>
          <Text style={sy.smallRegular}>
            Laatste update:&nbsp;
            {moment(importantNotification?.updated).format('DD/MM/YYYY HH:mm')}
          </Text>
        </View>
      </Dialog>
    </>
  );
};

export default DealerHeader;
