import React, {useState, useCallback, useEffect, useContext} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import UserContext from '~/components/context/UserContext';
import {useDispatch, useSelector} from '~/lib/hooks';
import {user as userApi} from '~/api/private';
import {setUserSettings} from '~/actions';
import {SOURCES} from '~/types/sources';

import {
  Modal,
  Pressable,
  Text,
  Link,
  Checkbox,
  RadioButton,
  ActionButton,
} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';

import CloseIcon from '~/images/md-icons/close/materialicons/24px.svg';
import SettingsIcon from '~/images/md-icons/settings/materialicons/24px.svg';
import LocalShippingIcon from '~/images/md-icons/local_shipping/materialicons/24px.svg';
import NotificationsIcon from '~/images/md-icons/notifications/materialicons/24px.svg';

const InitialTabActionsheet = ({
  user_settings,
  visible,
  onDismiss,
  onRefresh,
}) => (
  <Actionsheet visible={visible} onDismiss={onDismiss}>
    <Title>
      <Text style={[sy.largePlus, {lineHeight: 30}]}>Startpagina</Text>
    </Title>
    <Divider />
    <Content style={{padding: 0}}>
      <Pressable
        style={[sy['p-4'], sy['flex-row'], sy['items-center'], sy['gap-8']]}
        onPress={async () => {
          await userApi.update_settings({
            ...user_settings,
            initial_tab: 'Messages',
          });

          onRefresh();
        }}>
        <RadioButton checked={user_settings.initial_tab === 'Messages'} />
        <Text>Berichten</Text>
      </Pressable>
      <Divider style={[sy['ml-18'], {backgroundColor: '#f2f2f2'}]} />
      <Pressable
        style={[sy['p-4'], sy['flex-row'], sy['items-center'], sy['gap-8']]}
        onPress={async () => {
          await userApi.update_settings({
            ...user_settings,
            initial_tab: 'Search',
          });

          onRefresh();
        }}>
        <RadioButton checked={user_settings.initial_tab === 'Search'} />
        <Text>Zoeken</Text>
      </Pressable>
      <Divider style={[sy['ml-18'], {backgroundColor: '#f2f2f2'}]} />
      <Pressable
        style={[sy['p-4'], sy['flex-row'], sy['items-center'], sy['gap-8']]}
        onPress={async () => {
          await userApi.update_settings({
            ...user_settings,
            initial_tab: 'Calendar',
          });

          onRefresh();
        }}>
        <RadioButton checked={user_settings.initial_tab === 'Calendar'} />
        <Text>Kalender</Text>
      </Pressable>
    </Content>
    <Divider />
    <Actions>
      <ActionButton onPress={onDismiss}>OK</ActionButton>
    </Actions>
  </Actionsheet>
);

const SettingsModal = ({visible, onDismiss}) => {
  const dispatch = useDispatch();
  const {me} = useContext(UserContext);
  const [articleSources, setArticleSources] = useState([SOURCES.Fource]);

  useEffect(() => {
    const {dealers} = me ?? {};

    if (dealers?.some((dealer) => !!dealer.package_access?.aag?.token)) {
      setArticleSources((prevState) =>
        prevState.includes(SOURCES.PartsPoint)
          ? prevState
          : [...prevState, SOURCES.PartsPoint],
      );
    }
  }, [me]);

  const refresh = useCallback(() => {
    const fetch = async () => {
      const {user_settings} = await userApi.me();
      dispatch(setUserSettings(user_settings?.partner ?? {}));
    };

    fetch();
  }, [dispatch]);

  const [showInitialTabActionsheet, setShowInitialTabActionsheet] =
    useState(false);

  const user_settings = useSelector((state) => state.user_settings) ?? {};
  return (
    <>
      <InitialTabActionsheet
        user_settings={user_settings}
        visible={showInitialTabActionsheet}
        onDismiss={() => setShowInitialTabActionsheet(false)}
        onRefresh={refresh}
      />
      <Modal visible={visible} onDismiss={onDismiss}>
        <View
          style={[
            {height: 64},
            sy['flex-row'],
            sy['items-center'],
            sy['gap-8'],
          ]}>
          <Pressable style={[sy['p-4']]} onPress={onDismiss}>
            <CloseIcon fill="#4a4a49" />
          </Pressable>
          <View
            style={[sy['flex-1'], sy['items-center'], sy['justify-center']]}>
            <Text>Instellingen</Text>
          </View>
          <View style={[sy['p-4']]}>
            <SettingsIcon fill="#4a4a49" />
          </View>
        </View>
        <Divider />
        <View style={[sy['flex-1']]}>
          <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
            <Text>Startpagina</Text>
            <Link onPress={() => setShowInitialTabActionsheet(true)}>
              {user_settings.initial_tab === 'Messages' ? (
                <>Berichten</>
              ) : user_settings.initial_tab === 'Calendar' ? (
                <>Kalender</>
              ) : (
                <>Zoeken</>
              )}
            </Link>
          </View>
          <Divider />
          <View
            style={[
              sy['p-4'],
              sy['flex-row'],
              sy['gap-4'],
              sy['items-center'],
            ]}>
            <LocalShippingIcon fill="#4a4a49" />
            <Text style={sy.medium}>Artikelen</Text>
          </View>
          <Divider style={{backgroundColor: '#f2f2f2'}} />
          <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
            <View>
              <Text>Fource</Text>
            </View>
            <Checkbox
              disabled={true}
              checked={articleSources.includes(SOURCES.Fource)}
            />
          </View>
          <Divider style={{backgroundColor: '#f2f2f2'}} />
          <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
            <View>
              <Text>PartsPoint</Text>
            </View>
            <Checkbox
              disabled={true}
              checked={articleSources.includes(SOURCES.PartsPoint)}
            />
          </View>
          <Divider />
          <View
            style={[
              sy['p-4'],
              sy['flex-row'],
              sy['gap-4'],
              sy['items-center'],
            ]}>
            <NotificationsIcon fill="#4a4a49" />
            <Text style={sy.medium}>Meldingen</Text>
          </View>
          <Divider style={{backgroundColor: '#f2f2f2'}} />
          <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
            <View>
              <Text>Doorbelasting kosten TecRMI</Text>
              <Text style={[sy.small, sy['text-lightgray']]}>Pop-up tonen</Text>
            </View>
            <Checkbox
              onPress={async () => {
                await userApi.update_settings({
                  ...user_settings,
                  tecrmi_info_dialog_never_show:
                    !user_settings.tecrmi_info_dialog_never_show,
                });

                refresh();
              }}
              checked={!user_settings.tecrmi_info_dialog_never_show}
            />
          </View>
          <Divider style={{backgroundColor: '#f2f2f2'}} />
          <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
            <View>
              <Text>Notificatie onderdeel apart toegevoegd</Text>
              <Text style={[sy.small, sy['text-lightgray']]}>Pop-up tonen</Text>
            </View>
            <Checkbox
              onPress={async () => {
                await userApi.update_settings({
                  ...user_settings,
                  never_show_parts_split_dialog:
                    !user_settings.never_show_parts_split_dialog,
                });

                refresh();
              }}
              checked={!user_settings.never_show_parts_split_dialog}
            />
          </View>
          <Divider style={{backgroundColor: '#f2f2f2'}} />
          <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
            <View>
              <Text>Vraag tonen extra services onderhoud</Text>
              <Text style={[sy.small, sy['text-lightgray']]}>Pop-up tonen</Text>
            </View>
            <Checkbox
              onPress={async () => {
                await userApi.update_settings({
                  ...user_settings,
                  never_show_maintenance_extras_dialog:
                    !user_settings.never_show_maintenance_extras_dialog,
                });

                refresh();
              }}
              checked={!user_settings.never_show_maintenance_extras_dialog}
            />
          </View>
          <Divider style={{backgroundColor: '#f2f2f2'}} />
          <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
            <View>
              <Text>Bevestigen laten vervallen activiteit</Text>
              <Text style={[sy.small, sy['text-lightgray']]}>Pop-up tonen</Text>
            </View>
            <Checkbox
              onPress={async () => {
                await userApi.update_settings({
                  ...user_settings,
                  never_show_rob_task_cancel_dialog:
                    !user_settings.never_show_rob_task_cancel_dialog,
                });

                refresh();
              }}
              checked={!user_settings.never_show_rob_task_cancel_dialog}
            />
          </View>
        </View>
        <Divider />
        <View style={[sy['py-2'], sy['flex-row'], sy['justify-end']]}>
          <ActionButton onPress={onDismiss}>OK</ActionButton>
        </View>
      </Modal>
    </>
  );
};

export default SettingsModal;
