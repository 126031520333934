import React, {useState} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {
  Text,
  Link,
  Pressable,
  RadioButton,
  Dialog,
  FormNumberInput,
  ActionButton,
} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import Format from '~/lib/format';
import {ROB_LENIENCY_OPTIONS} from '~/types/rob';

const LENIENCY_LABELS = {
  LeniencyRequested: 'Aangevraagd',
  LeniencyResolved: 'Afgewikkeld',
  LeniencyDenied: 'Afgewezen',
};

const SCREENS = {
  OVERVIEW: 'overview',
  STATUS: 'status',
};

export default ({onDismiss, value, task_amount, onChange}) => {
  const [screen, setScreen] = useState(SCREENS.OVERVIEW);
  const [selected, setSelected] = useState(ROB_LENIENCY_OPTIONS[value]);

  const [showEditNumber, setShowEditNumber] = useState(null);
  const [amount, setAmount] = useState(task_amount);

  const onOK = () => {
    onChange(selected, amount);
  };

  return (
    <>
      <Actionsheet visible={true} onDismiss={onDismiss} dismissable={false}>
        {screen === SCREENS.OVERVIEW && (
          <>
            <Title>Coulance</Title>
            <Divider />
            <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
              <View
                style={[
                  sy['p-4'],
                  sy['flex-row'],
                  sy['items-center'],
                  sy['justify-between'],
                ]}>
                <Text style={sy.mediumPlus}>Status</Text>
                <Link onPress={() => setScreen(SCREENS.STATUS)}>
                  {LENIENCY_LABELS[selected] ?? <>Keuze maken</>}
                </Link>
              </View>
              <View
                style={[
                  sy['p-4'],
                  sy['flex-row'],
                  sy['items-center'],
                  sy['justify-between'],
                ]}>
                <Text style={sy.mediumPlus}>Bedrag</Text>
                <Pressable
                  style={{
                    padding: 5,
                    backgroundColor: '#F2F2F2',
                    borderRadius: 2,
                  }}
                  onPress={() =>
                    setShowEditNumber({
                      title: 'Coulance',
                      label: 'Bedrag',
                      value: amount,
                      onChange: (value) => setAmount(value),
                    })
                  }>
                  <Text style={[sy.small]}>{Format.price(amount ?? 0)}</Text>
                </Pressable>
              </View>
            </Content>
            <Divider />
            <Actions>
              <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
              <ActionButton onPress={onOK}>OK</ActionButton>
            </Actions>
          </>
        )}
        {screen === SCREENS.STATUS && (
          <>
            <Title>Status</Title>
            <Divider />
            <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
              {Object.keys(ROB_LENIENCY_OPTIONS).map((item) => (
                <Pressable
                  key={item}
                  style={[sy['flex-row']]}
                  onPress={() => setSelected(item)}>
                  <View style={[sy['p-4'], sy['pr-8']]}>
                    <RadioButton checked={selected === item} />
                  </View>
                  <View
                    style={[
                      sy['flex-row'],
                      sy['flex-1'],
                      sy['justify-between'],
                      sy['py-4'],
                      sy['pr-4'],
                      sy['border-b'],
                      sy['border-lightgray'],
                    ]}>
                    <Text>{LENIENCY_LABELS[item]}</Text>
                  </View>
                </Pressable>
              ))}
            </Content>
            <Divider />
            <Actions>
              <ActionButton onPress={() => setScreen(SCREENS.OVERVIEW)}>
                OK
              </ActionButton>
            </Actions>
          </>
        )}
      </Actionsheet>
      {showEditNumber && (
        <Dialog
          visible={true}
          title={showEditNumber.title}
          initialValue={showEditNumber.value}
          onDismiss={({initialValue}) => {
            showEditNumber.onChange(initialValue);
            setShowEditNumber(null);
          }}
          buttons={[
            {
              text: 'Annuleren',
              onPress: ({initialValue}) => {
                showEditNumber.onChange(initialValue);
                setShowEditNumber(null);
              },
            },
            {
              text: 'OK',
              onPress: () => setShowEditNumber(null),
            },
          ]}>
          <View style={[sy['py-4']]}>
            <FormNumberInput
              label={showEditNumber.label}
              value={showEditNumber.value}
              digits={2}
              onChangeNumber={showEditNumber.onChange}
            />
          </View>
        </Dialog>
      )}
    </>
  );
};
