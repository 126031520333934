import React from 'react';
import {View} from 'react-native';

import Text from '../Text';

import sy from '~/styles';
import CoffeeIcon from '~/images/md-icons/local_cafe/materialicons/24px.svg';
import MarkChatRead from '~/images/md-icons/mark_chat_read/materialicons/24px.svg';
import PedalBikeIcon from '~/images/md-icons/pedal_bike/materialicons/24px.svg';
import SyncAltIcon from '~/images/md-icons/sync_alt/materialicons/24px.svg';
import DirectionsCarIcon from '~/images/md-icons/directions_car/materialicons/24px.svg';

export default ({type, ...rest}) => {
  const ICON = {
    wait: <CoffeeIcon {...rest} />,
    message: <MarkChatRead {...rest} />,
    bike: <PedalBikeIcon {...rest} />,
    transport: <SyncAltIcon {...rest} />,
    car: <DirectionsCarIcon {...rest} />,
  };

  return (
    ICON[type] ?? (
      <View
        style={{
          width: 24,
          height: 24,
          backgroundColor: '#f2f2f2',
          borderRadius: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Text style={sy.xSmallMedium}>?</Text>
      </View>
    )
  );
};
