import React from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import sy from '~/styles';
import {Dialog, FormNumberInput, Text} from '~/components/controls';
import Format from '~/lib/format';

const DurationDialog = ({priceCheck, duration, onChange, onDismiss}) => (
  <Dialog
    visible={true}
    title={`Arbeidstijd ${
      _.isNumber(priceCheck?.suggested_duration) ? priceCheck?.rob_code : ''
    }`}
    initialValue={duration}
    onDismiss={({initialValue}) => {
      onChange(initialValue);
      onDismiss();
    }}
    buttons={[
      {
        text: 'Annuleren',
        onPress: ({initialValue}) => {
          onChange(initialValue);
          onDismiss();
        },
      },
      {
        text: 'OK',
        onPress: onDismiss,
      },
    ]}>
    <View style={[sy['py-4']]}>
      <FormNumberInput
        label="Aantal uren"
        value={duration}
        digits={2}
        onChangeNumber={onChange}
      />
      {_.isNumber(priceCheck?.suggested_duration) && (
        <Text style={[sy.smallRegular, sy['text-lightgray'], sy['pl-3']]}>
          {Format.number(priceCheck?.suggested_duration, 2)}
          &nbsp;uur&nbsp;meest&nbsp;goedgekeurd
        </Text>
      )}
    </View>
  </Dialog>
);

export default DurationDialog;
