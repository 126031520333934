import React from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import Format from '~/lib/format';

import sy from '~/styles';
import {Text, Menu} from '~/components/controls';

import LooksOneIcon from '~/images/md-icons/looks_one/materialicons/24px.svg';
import LooksTwoIcon from '~/images/md-icons/looks_two/materialicons/24px.svg';
import Looks3Icon from '~/images/md-icons/looks_3/materialicons/24px.svg';
import Looks4Icon from '~/images/md-icons/looks_4/materialicons/24px.svg';
import Looks5Icon from '~/images/md-icons/looks_5/materialicons/24px.svg';
import Looks6Icon from '~/images/md-icons/looks_6/materialicons/24px.svg';
import DiscountIcon from '~/images/md-icons/discount/materialicons/24px.svg';

const LOOKS_ICONS = [
  <LooksOneIcon
    width={18}
    height={18}
    fill="#4A4A49"
    style={{...sy['pr-2']}}
  />,
  <LooksTwoIcon
    width={18}
    height={18}
    fill="#4A4A49"
    style={{...sy['pr-2']}}
  />,
  <Looks3Icon width={18} height={18} fill="#4A4A49" style={{...sy['pr-2']}} />,
  <Looks4Icon width={18} height={18} fill="#4A4A49" style={{...sy['pr-2']}} />,
  <Looks5Icon width={18} height={18} fill="#4A4A49" style={{...sy['pr-2']}} />,
  <Looks6Icon width={18} height={18} fill="#4A4A49" style={{...sy['pr-2']}} />,
];

const LOOKS_WHITE_ICONS = [
  <LooksOneIcon
    width={18}
    height={18}
    fill="#FFFFFF"
    style={{...sy['pr-2']}}
  />,
  <LooksTwoIcon
    width={18}
    height={18}
    fill="#FFFFFF"
    style={{...sy['pr-2']}}
  />,
  <Looks3Icon width={18} height={18} fill="#FFFFFF" style={{...sy['pr-2']}} />,
  <Looks4Icon width={18} height={18} fill="#FFFFFF" style={{...sy['pr-2']}} />,
  <Looks5Icon width={18} height={18} fill="#FFFFFF" style={{...sy['pr-2']}} />,
  <Looks6Icon width={18} height={18} fill="#FFFFFF" style={{...sy['pr-2']}} />,
];

export default ({
  lessor,
  order,
  discount,
  manufacturerSuggestedRetailPrice,
  onDismiss,
}) => {
  if (!(discount > 0) && !(order > 0)) {
    return <></>;
  }

  return (
    <Menu
      anchor={
        !_.isNumber(order) ? (
          <DiscountIcon
            width={18}
            height={18}
            fill="#4A4A49"
            style={{...sy['pr-2']}}
          />
        ) : (
          LOOKS_ICONS[order - 1]
        )
      }
      style={{marginTop: 0}}
      contentStyle={{backgroundColor: '#6D6D6D'}}
      onDismiss={onDismiss}>
      <View style={[sy['px-2'], sy['flex-row']]}>
        {!_.isNumber(order) ? (
          <DiscountIcon
            width={18}
            height={18}
            fill="#FFFFFF"
            style={{...sy['pr-2']}}
          />
        ) : discount > 0 ? (
          LOOKS_WHITE_ICONS[order - 1]
        ) : (
          <></>
        )}
        {
          <Text
            style={[sy.smallMedium, {color: '#FFFFFF', whiteSpace: 'nowrap'}]}>
            {lessor}
          </Text>
        }
        {_.isNumber(discount) && discount > 0 && (
          <>
            <Text style={sy.smallMedium}>&nbsp;</Text>
            <Text
              style={[
                sy.smallMedium,
                {color: '#FFFFFF', whiteSpace: 'nowrap'},
              ]}>
              {Format.number((1 - discount) * 100)}% korting
            </Text>
            <Text style={sy.smallMedium}>&nbsp;</Text>
            <Text
              style={[
                sy.smallMedium,
                sy['line-through'],
                {color: '#FFFFFF', whiteSpace: 'nowrap'},
              ]}>
              {Format.price(manufacturerSuggestedRetailPrice)}
            </Text>
          </>
        )}
      </View>
    </Menu>
  );
};
