import React, {useState, useEffect} from 'react';
import {View, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {
  Pressable,
  RadioButton,
  ReplacementIcon,
  ReplacementText,
  Text,
  ActionButton,
} from '~/components/controls';
import Actionsheet, {Title, Actions} from '~/components/controls/Actionsheet';
import {
  service_request as serviceRequestApi,
  rob as robApi,
  fleet as fleetApi,
} from '~/api/private';
import {useDispatch} from '~/lib/hooks';
import {setAppDoneLoading, setAppIsLoading} from '~/actions';

import {REPLACEMENT_TYPE} from '~/types/replacement';
import {REQUEST_TYPES} from '~/types/request';

const REPLACEMENT_ROB_CODE_SLA = {
  [REPLACEMENT_TYPE.transport]: '1039',
};

export default ({
  request_id,
  license,
  fleet_contract_id,
  parent_request_type,
  type,
  visible,
  onDismiss,
  onTypeChanged,
}) => {
  const [loading, setLoading] = useState(true);
  const [editType, setEditType] = useState(null);
  const [sla, setSla] = useState(null);
  const [robContract, setRobContract] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      const {result: sla} = await serviceRequestApi.sla(request_id);
      const replacement_sla = Object.keys(REPLACEMENT_ROB_CODE_SLA).reduce(
        (acc, key) => {
          const rob_code = REPLACEMENT_ROB_CODE_SLA[key];
          const sla_item = sla?.codes?.find(
            (item) => item.rob_code === rob_code,
          );

          if (sla_item?.amount > 0) {
            acc[key] = {
              rob_code,
              amount: sla_item?.amount,
            };
          }

          return acc;
        },
        {},
      );

      setSla(replacement_sla);

      if (fleet_contract_id) {
        const {result} = await fleetApi.contract(fleet_contract_id);
        setRobContract(result.contract);
      } else {
        const {result: contract} = await robApi.contract(license, request_id);
        setRobContract(contract);
      }

      setLoading(false);
    };

    fetch();
  }, [request_id, license]);

  useEffect(() => {
    setEditType(type ?? REPLACEMENT_TYPE.none);
  }, [type]);

  const onChange = async (changes) => {
    if (type === editType) {
      onTypeChanged(type);
      return;
    }

    dispatch(setAppIsLoading());
    try {
      const result = await serviceRequestApi.patch(request_id, {
        replacement: {
          ...changes,
        },
      });

      if (result?.success) {
        onTypeChanged(editType);
      }
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  return (
    <Actionsheet visible={visible} onDismiss={onDismiss}>
      {loading ? (
        <View style={[sy['p-4']]}>
          <ActivityIndicator size="large" color="#231fda" />
        </View>
      ) : (
        <>
          <Title>Vervangend vervoer</Title>
          <Divider />
          {Object.values(REPLACEMENT_TYPE).map((type, index, arr) => {
            let allowed = true;
            if (
              parent_request_type !== REQUEST_TYPES.SERVICE_REQUEST &&
              type === REPLACEMENT_TYPE.car
            ) {
              allowed = robContract?.permissions.replacement_vehicle?.allowed;
            }

            return (
              <Pressable
                key={type}
                disabled={!allowed}
                style={[sy['flex-row'], sy['flex-1']]}
                onPress={() => setEditType(type)}>
                <View style={[sy['p-4']]}>
                  <RadioButton
                    disabled={!allowed}
                    checked={editType === type}
                  />
                </View>
                <View style={[sy['flex-1'], sy['pl-4']]}>
                  <View
                    style={[
                      sy['flex-row'],
                      sy['flex-1'],
                      sy['items-center'],
                      sy['justify-between'],
                      sy['py-4'],
                      sy['pr-4'],
                    ]}>
                    <View>
                      <ReplacementText type={type} />
                      {!allowed && (
                        <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                          Niet in contract
                        </Text>
                      )}
                    </View>
                    <ReplacementIcon type={type} fill="#4A4A49" />
                  </View>
                  {index < arr.length - 1 && (
                    <Divider style={[{backgroundColor: '#f2f2f2'}]} />
                  )}
                </View>
              </Pressable>
            );
          })}

          <Divider />
          <Actions>
            <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
            <ActionButton
              onPress={() => {
                if (editType === REPLACEMENT_TYPE.none) {
                  onChange({});
                } else {
                  onChange({
                    type: editType,
                    sla: {
                      amount: 0.0,
                      ...(sla[editType] || {}),
                    },
                  });
                }
              }}>
              OK
            </ActionButton>
          </Actions>
        </>
      )}
    </Actionsheet>
  );
};
