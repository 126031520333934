import React, {useState, useEffect} from 'react';
import {View, ActivityIndicator, ScrollView} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {
  Pressable,
  Text,
  Modal,
  Link,
  Dialog,
  FormTextInput,
  Select,
  RadioButton,
  ActionButton,
} from '~/components/controls';
import Format from '~/lib/format';
import {car as carApi, dealer as dealerApi, rob as robApi} from '~/api/private';

import CloseIcon from '~/images/md-icons/close/materialicons/24px.svg';
import CarRentalIcon from '~/images/md-icons/car_rental/materialicons/24px.svg';
import AddIcon from '~/images/md-icons/add/materialicons/24px.svg';
import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';

const RentalClassText = ({
  style,
  rental_class,
  rental_classes,
  contract_rental_class = null,
  selected_rental_class = null,
}) => (
  <Text style={style}>
    {rental_class}-
    {rental_classes?.find((item) => item.code === rental_class)?.description}
    {rental_class === contract_rental_class &&
    rental_class === selected_rental_class ? (
      <>&nbsp;(contract&nbsp;&amp;&nbsp;inzet)</>
    ) : rental_class === contract_rental_class ? (
      <>&nbsp;(contract)</>
    ) : rental_class === selected_rental_class ? (
      <>&nbsp;(inzet)</>
    ) : (
      <></>
    )}
  </Text>
);

const RentalCarModal = ({
  visible,
  dealer_id,
  license,
  fuel,
  rental_class,
  contract_rental_class,
  onChange,
  onClose,
}) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(license);
  const [rental_classes, setRentalClasses] = useState(null);
  const [addCar, setAddCar] = useState(null);
  const [rentalCars, setRentalCars] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const {result: rental_classes} = await robApi.rental_classes();
      setRentalClasses(rental_classes);

      const {result: rental_cars} = await dealerApi.rental_cars({
        dealer_id,
      });
      setRentalCars(rental_cars);

      setLoading(false);
    };

    fetch();
  }, [dealer_id]);

  useEffect(() => {
    setSelected(license);
  }, [license, visible]);

  const onAddCar = async () => {
    if (
      !addCar?.license ||
      !addCar?.name ||
      !addCar?.gearbox ||
      !addCar?.rental_class
    ) {
      return;
    }

    const {
      license,
      name,
      rental_class,
      dealer_id: add_car_dealer_id,
      ...attributes
    } = addCar;

    const result = await dealerApi.addEditRentalCar(
      add_car_dealer_id ?? dealer_id,
      {
        license,
        name,
        attributes: {
          ...attributes,
          rental_class,
        },
      },
    );

    if (result.success) {
      const {result: rental_cars} = await dealerApi.rental_cars({dealer_id});
      setRentalCars(rental_cars);

      setAddCar(null);
      setSelected(license);
    }
  };

  const onDeleteCar = async () => {
    const result = await dealerApi.deleteRentalCar(addCar.id);

    if (result.success) {
      const {result: rental_cars} = await dealerApi.rental_cars({dealer_id});
      setRentalCars(rental_cars);
      setAddCar(null);
    }
  };

  const onOK = async () => {
    const car = rentalCars.find((item) => item.license === selected);
    if (car) {
      onChange({
        license: car.license,
        name: car.name,
        rental_class: car.attributes.rental_class,
        fuel: car.attributes.fuel,
        gearbox: car.attributes.gearbox,
        is_estate: car.attributes.is_estate,
      });
    } else {
      onChange(null);
    }

    onClose();
  };

  return (
    <>
      <Modal visible={visible} onDismiss={onClose}>
        <View
          style={[
            {height: 64},
            sy['flex-row'],
            sy['items-center'],
            sy['gap-8'],
          ]}>
          <Pressable style={[sy['p-4']]} onPress={onOK}>
            <CloseIcon />
          </Pressable>

          <View style={[sy['flex-1']]}>
            <Text style={[sy.largePlus, sy['text-center']]}>Auto inzet</Text>
          </View>

          <View style={[sy['p-4']]}>
            <CarRentalIcon fill="#4a4a49" />
          </View>
        </View>
        <Divider />
        {loading && (
          <View style={[sy['flex-1'], sy['p-4']]}>
            <ActivityIndicator size="large" color="#231fda" />
          </View>
        )}
        {!loading && (
          <>
            <ScrollView>
              {rentalCars.length > 0 && (
                <View style={[sy['flex-row']]}>
                  <Pressable
                    onPress={() => {
                      setSelected(null);
                    }}>
                    <View style={sy['p-4']}>
                      <RadioButton checked={!selected} />
                    </View>
                  </Pressable>
                  <View
                    style={[
                      sy['py-4'],
                      sy['border-b'],
                      sy['border-lightgray'],
                      sy['flex-1'],
                    ]}>
                    <Text>Geen keuze</Text>
                  </View>
                </View>
              )}
              {rentalCars?.map((car) => (
                <View key={car.license} style={[sy['flex-row']]}>
                  <Pressable
                    onPress={() => {
                      setSelected(car.license);
                    }}>
                    <View style={sy['p-4']}>
                      <RadioButton checked={car.license === selected} />
                    </View>
                  </Pressable>
                  <View
                    style={[
                      sy['py-4'],
                      sy['border-b'],
                      sy['border-lightgray'],
                      sy['flex-1'],
                    ]}>
                    <Text>
                      {Format.license(car.license)}&nbsp;&middot;&nbsp;
                      {car.name}
                    </Text>

                    <Link
                      style={{display: 'inline', flex: 0}}
                      onPress={() =>
                        setAddCar({
                          ...car,
                          ...(car.attributes ?? {}),
                        })
                      }>
                      <RentalClassText
                        style={[sy.small, sy['text-lightgray']]}
                        rental_class={car.attributes?.rental_class}
                        rental_classes={rental_classes}
                        contract_rental_class={contract_rental_class}
                        selected_rental_class={rental_class}
                      />
                    </Link>
                  </View>
                </View>
              ))}
              <Pressable
                style={[sy['flex-row'], sy['p-4']]}
                onPress={() => {
                  setAddCar({
                    rental_class: rental_classes[0].code,
                    fuel: 'gasoline',
                    gearbox: 'manual',
                    is_estate: false,
                  });
                }}>
                <AddIcon fill="#2907E3" />
                <Text style={[sy['pl-4']]}>Auto toevoegen</Text>
              </Pressable>
            </ScrollView>
            <Divider />
            <View
              style={[
                sy['flex-row'],
                sy['p-2'],
                sy['gap-2'],
                sy['justify-end'],
              ]}>
              <ActionButton onPress={onClose}>Annuleren</ActionButton>
              <ActionButton onPress={onOK}>OK</ActionButton>
            </View>
          </>
        )}
      </Modal>
      <Dialog
        visible={!!addCar}
        title={
          addCar?.id ? (
            <View
              style={[
                sy['flex-row'],
                sy['justify-between'],
                sy['items-center'],
                {
                  display: 'flex',
                },
              ]}>
              <Text style={[sy.large]}>Huurauto bewerken</Text>
            </View>
          ) : (
            <Text style={[sy.large]}>Huurauto toevoegen</Text>
          )
        }
        titleIcon={
          addCar?.id ? (
            <Pressable onPress={() => onDeleteCar()}>
              <DeleteIcon fill="#4A4A49" />
            </Pressable>
          ) : null
        }
        onDismiss={() => setAddCar(null)}
        buttons={[
          {
            text: 'Annuleren',
            textStyle: {color: '#828282'},
            onPress: () => setAddCar(null),
          },
          {
            text: 'OK',
            textStyle: {color: '#972727'},
            onPress: onAddCar,
          },
        ]}>
        <View style={[sy['py-4']]}>
          <FormTextInput
            value={addCar?.license}
            onChangeText={(text) => {
              const license = text?.replace(/[^0-9a-z]/gi, '').toUpperCase();
              setAddCar({
                ...addCar,
                license,
                name: null,
              });
            }}
            onEndEditing={async (license) => {
              if (license?.length > 5) {
                const car = await carApi.fetch(license);

                setAddCar((prevState) => ({
                  ...prevState,
                  license,
                  name: car.name,
                }));
              }
            }}
            label="Kenteken"
            required={true}
            errorMessage={'Kenteken is verplicht'}
            inputProps={{
              maxLength: 8,
              autoCapitalize: 'characters',
            }}
          />
          {addCar?.name && (
            <Text style={[sy['pl-2'], sy['text-lightgray']]}>
              {addCar.name}
            </Text>
          )}
        </View>
        <View style={[sy['py-4'], sy['pt-0']]}>
          <Select
            label="Klasse"
            value={addCar?.rental_class}
            options={rental_classes?.map((item) => ({
              text: `${item.code} - ${item.description}`,
              value: item.code,
            }))}
            onChange={(option) =>
              setAddCar((prevState) => ({
                ...prevState,
                rental_class: option.value,
              }))
            }
          />
        </View>
        <View style={[sy['py-4'], sy['pt-0']]}>
          <Select
            label="Brandstof"
            value={addCar?.fuel ?? fuel}
            options={[
              {text: 'Benzine', value: 'gasoline'},
              {text: 'Diesel', value: 'diesel'},
              {text: 'Elektrisch', value: 'electric'},
              {text: 'Hybride', value: 'hybrid'},
              {text: 'LNG', value: 'lng'},
              {text: 'LPG', value: 'lpg'},
              {text: 'Overig', value: 'other'},
            ]}
            onChange={(option) =>
              setAddCar((prevState) => ({
                ...prevState,
                fuel: option.value,
              }))
            }
          />
        </View>
        <View style={[sy['py-4'], sy['pt-0']]}>
          <Select
            label="Transmissie"
            value={addCar?.gearbox ?? 'manual'}
            options={[
              {text: 'Handgeschakeld', value: 'manual'},
              {text: 'Automaat', value: 'automatic'},
            ]}
            onChange={(option) =>
              setAddCar((prevState) => ({
                ...prevState,
                gearbox: option.value,
              }))
            }
          />
        </View>
        <View style={[sy['py-4'], sy['pt-0']]}>
          <Select
            label="Carrosserievorm"
            value={`${addCar?.is_estate ?? false}`}
            options={[
              {text: 'Overig', value: 'false'},
              {text: 'Stationwagon', value: 'true'},
            ]}
            onChange={(option) =>
              setAddCar((prevState) => ({
                ...prevState,
                is_estate: option.value === 'true',
              }))
            }
          />
        </View>
      </Dialog>
    </>
  );
};

export default RentalCarModal;
