import React from 'react';

import {Pressable} from '~/components/controls';

const Thumbnail = ({data}) => {
  return (
    <Pressable
      onPress={() => {
        const w = window.open(
          null,
          'bahnkick_image',
          'popup,width=800,height=600',
        );
        w.document.write(`<img src="${data}" width="100%" />`);
      }}>
      <img
        draggable="false"
        style={{
          maxWidth: '50%',
          borderRadius: 10,
        }}
        src={data}
      />
    </Pressable>
  );
};

export default Thumbnail;
