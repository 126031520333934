import React, {useEffect, useState, useRef, useCallback} from 'react';
import {View, ScrollView, ActivityIndicator, TextInput} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {
  Text,
  RadioButton,
  Checkbox,
  Pressable,
  Modal,
  ActionButton,
} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import {
  service_request as serviceRequestApi,
  rob as robApi,
} from '~/api/private';
import withDimensions from '~/components/hoc/with-dimensions';

import AddIcon from '~/images/md-icons/add/materialicons/24px.svg';
import ArrowBackIcon from '~/images/md-icons/arrow_back/materialicons/24px.svg';

const HighlightText = ({text, highlight}) => {
  const position = text.toLowerCase().indexOf(highlight.toLowerCase());

  return (
    <>
      {position === -1 ? (
        <Text>{text}</Text>
      ) : (
        <>
          {position > 0 && <Text>{text.substring(0, position)}</Text>}
          <Text style={[{lineHeight: 16}, sy.mediumBold]}>
            {text.substring(position, position + highlight.length)}
          </Text>
          {position + highlight.length < text.length && (
            <Text>{text.substring(position + highlight.length)}</Text>
          )}
        </>
      )}
    </>
  );
};

const HEADER_HEIGHT_IN_PX = 72;
const FOOTER_HEIGHT_IN_PX = 52;

const RobCode = ({
  dimensions,
  dismissable = true,
  onDismiss,
  request_id,
  license,
  codes: initial_codes,
  rob_code,
  onChange,
  options = {
    maintenance_codes: false,
    maintenance_codes_only: false,
  },
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedRobCode, setSelectedRobCode] = useState(rob_code);
  const [codes, setCodes] = useState([
    ...new Set([...(initial_codes ?? []), rob_code].filter(Boolean)),
  ]);
  const [rob_components, setRobComponents] = useState(null);

  const [search, setSearch] = useState(false);
  const [results, setResults] = useState([]);
  const [selection, setSelection] = useState([]);
  const [highlighSearchText, setHighlightSearchText] = useState('');
  const searchTextRef = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      const {result: rob_components} = await robApi.components(
        license,
        request_id,
      );

      setRobComponents(rob_components);

      if (loading && !codes.length) {
        setSearch(true);
      }

      setLoading(false);
    };

    fetch();
  }, [license, request_id, rob_code, codes]);

  const onPrefixChange = useCallback(
    _.debounce(async (value) => {
      if (value.length < 1) {
        setResults([]);
        return;
      }

      const {result, success} = await serviceRequestApi.search_rob_code(
        request_id,
        value,
        options,
      );

      if (searchTextRef.current?.value === value && success) {
        setResults(result);
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (!search) {
      setResults([]);
      setSelection([]);
      setHighlightSearchText('');
    }
  }, [search]);

  const onSearchDismiss = () => {
    if (selection.length === 1) {
      onChange(selection[0], rob_components[selection[0]].description);
    } else {
      setCodes([...codes, ...selection]);
      setSearch(false);
    }
  };

  return (
    <>
      <Actionsheet
        visible={true}
        dismissable={dismissable}
        onDismiss={onDismiss}>
        {loading && (
          <View style={[sy['p-4']]}>
            <ActivityIndicator size="large" color="#231fda" />
          </View>
        )}
        {!loading && (
          <>
            <Title>ROB activiteit</Title>
            <Divider />
            <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
              <ScrollView
                contentContainerStyle={{
                  maxHeight:
                    dimensions.window.height -
                    HEADER_HEIGHT_IN_PX -
                    FOOTER_HEIGHT_IN_PX,
                }}>
                {codes.map((rob_code) => (
                  <View key={rob_code}>
                    <View style={[sy['p-4'], sy['flex-row']]}>
                      <View style={[sy['pr-8']]}>
                        <RadioButton
                          checked={selectedRobCode === rob_code}
                          onPress={() => setSelectedRobCode(rob_code)}
                        />
                      </View>
                      <Text>
                        {rob_code}&nbsp;&middot;&nbsp;
                        {rob_components[rob_code]?.description}
                      </Text>
                    </View>
                    <Divider style={[{backgroundColor: '#f2f2f2'}]} />
                  </View>
                ))}
                <Pressable
                  style={[sy['p-4'], sy['flex-row']]}
                  onPress={() => setSearch(true)}>
                  <View style={[sy['pr-8']]}>
                    <AddIcon fill="#231fda" />
                  </View>
                  <Text>Keuze toevoegen</Text>
                </Pressable>
              </ScrollView>
            </Content>
            <Divider />

            <Actions>
              <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
              <ActionButton
                disabled={!selectedRobCode}
                onPress={() =>
                  onChange(
                    selectedRobCode,
                    rob_components[selectedRobCode].description,
                  )
                }>
                OK
              </ActionButton>
            </Actions>
          </>
        )}
      </Actionsheet>
      {search && (
        <Modal visible={true} onDismiss={onSearchDismiss}>
          <View
            style={[
              {height: 64},
              sy['flex-row'],
              sy['border-b'],
              sy['border-gray'],
              sy['items-center'],
            ]}>
            <Pressable
              style={[sy['p-4'], sy['pr-8']]}
              onPress={onSearchDismiss}>
              <ArrowBackIcon />
            </Pressable>

            <TextInput
              ref={(ref) => {
                searchTextRef.current = ref;
                ref?.focus();
              }}
              style={[sy.regular, sy['flex-1']]}
              placeholder={'Zoek op ROB code of naam'}
              onChangeText={(value) => {
                onPrefixChange.cancel();
                onPrefixChange(value);
                setHighlightSearchText(value);
              }}
            />
          </View>
          <ScrollView>
            {results.map((result) => (
              <React.Fragment key={result.rob_code}>
                <View style={[sy['flex-row'], sy['p-4'], sy['gap-8']]}>
                  <Pressable
                    onPress={() => {
                      if (codes.includes(result.rob_code)) {
                        setCodes(
                          codes.filter((code) => code !== result.rob_code),
                        );
                      } else if (selection.includes(result.rob_code)) {
                        setSelection(
                          selection.filter((code) => code !== result.rob_code),
                        );
                      } else {
                        setSelection([
                          ...new Set([...selection, result.rob_code]),
                        ]);
                      }
                    }}>
                    <Checkbox
                      style={{width: 'auto'}}
                      checked={selection
                        .concat(codes)
                        .includes(result.rob_code)}
                    />
                  </Pressable>
                  <View style={[sy['flex-1']]}>
                    <View style={{display: 'block'}}>
                      <HighlightText
                        text={`${result.rob_code} · ${result.description}`}
                        highlight={highlighSearchText}
                      />
                    </View>
                  </View>
                </View>
                <View style={[sy['pl-18']]}>
                  <Divider style={[{backgroundColor: '#f2f2f2'}]} />
                </View>
              </React.Fragment>
            ))}
          </ScrollView>
        </Modal>
      )}
    </>
  );
};

export default withDimensions(RobCode);
