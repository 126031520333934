import React, {useCallback, useEffect, useState} from 'react';
import {View, ScrollView} from 'react-native';
import _ from 'lodash';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {kvk as kvkApi} from '~/api/public';
import {
  Dialog,
  FormTextInput,
  RadioButton,
  Text,
  Pressable,
} from '~/components/controls';
import withDimensions from '~/components/hoc/with-dimensions';

const DIALOG_HEADER_IN_PIXELS = 65;
const DIALOG_FOOTER_IN_PIXELS = 51;

const doSearch = _.debounce(async (prefix, setResults, setSelected) => {
  const {success, result} = await kvkApi.search(prefix);
  if (success) {
    setResults(result);
    setSelected(null);
  }
}, 500);

const KvkSearchDialog = ({dimensions, visible, onDismiss, onOK}) => {
  const [results, setResults] = useState([]);
  const [selected, setSelected] = useState(null);

  const onPrefixChange = useCallback(
    (value) => {
      if (value?.length > 2) {
        doSearch(value, setResults, setSelected);
      } else {
        setResults([]);
        setSelected(null);
      }
    },
    [setResults, setSelected],
  );

  useEffect(() => {
    if (!visible) {
      setResults([]);
      setSelected(null);
    }
  }, [visible]);

  return (
    <Dialog
      visible={visible}
      title="Kamer van Koophandel"
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Annuleren',
          onPress: onDismiss,
        },
        {
          text: 'OK',
          disabled: !selected,
          onPress: () => onOK(selected),
        },
      ]}>
      <ScrollView
        contentContainerStyle={{
          maxHeight:
            dimensions.window.height -
            DIALOG_HEADER_IN_PIXELS -
            DIALOG_FOOTER_IN_PIXELS,
        }}>
        <View style={[sy['py-4'], sy['gap-4']]}>
          <FormTextInput
            label="Zoek op KVK naam of nummer"
            onChangeText={onPrefixChange}
          />
          {results.map((result, index, arr) => (
            <View key={result.number}>
              <Pressable
                style={[sy['flex-row'], sy['gap-8']]}
                onPress={() => setSelected(result)}>
                <RadioButton
                  label={result.name}
                  value={result.number}
                  checked={selected?.number === result.number}
                />
                <View style={sy['flex-1']}>
                  <Text>{result.name}</Text>
                  <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                    KVK-nummer: {result.number}
                  </Text>
                </View>
              </Pressable>
              {index < arr.length - 1 && (
                <Divider
                  style={[
                    sy['ml-14'],
                    sy['mt-4'],
                    {backgroundColor: '#f2f2f2'},
                  ]}
                />
              )}
            </View>
          ))}
        </View>
      </ScrollView>
    </Dialog>
  );
};

export default withDimensions(KvkSearchDialog);
