import React, {useState} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';

import {Pressable, Dialog, Text, Checkbox} from '~/components/controls';
import {LOCATION_TEXT} from '~/types/rob';

import sy from '~/styles';

import LinkIcon from '~/images/md-icons/link/materialicons/24px.svg';
import LinkOffIcon from '~/images/md-icons/link_off/materialicons/24px.svg';

const Linking = ({value, location, options, onChange}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [selected, setSelected] = useState(value ?? []);

  return (
    <>
      <Pressable style={[sy['mr-8']]} onPress={() => setShowDialog(true)}>
        {value ? <LinkIcon fill="#4a4a49" /> : <LinkOffIcon fill="#4a4a49" />}
      </Pressable>
      <Dialog
        visible={showDialog}
        onDismiss={() => {
          setSelected(value ?? []);
          setShowDialog(false);
        }}
        title={
          <View style={[sy['flex-row'], sy['items-center']]}>
            {selected.length > 0 ? (
              <>
                <LinkIcon fill="#4a4a49" />
                <Text style={[sy['ml-4'], sy.largePlus]}>Linking aan</Text>
              </>
            ) : (
              <>
                <LinkOffIcon fill="#4a4a49" />
                <Text style={[sy['ml-4'], sy.largePlus]}>Linking uit</Text>
              </>
            )}
          </View>
        }
        options={{
          noPaddingContent: true,
        }}
        buttons={[
          {
            text: 'Annuleren',
            onPress: () => {
              setSelected(value ?? []);
              setShowDialog(false);
            },
          },
          {
            text: 'OK',
            onPress: () => {
              onChange(selected.length > 0 ? selected : null);
              setShowDialog(false);
            },
          },
        ]}>
        <View style={[sy['py-4'], sy['px-6'], sy['gap-8']]}>
          {options.map((option) => (
            <Pressable
              key={option}
              disabled={option === location}
              style={[sy['flex-row'], sy['flex-1'], sy['gap-4']]}
              onPress={() => {
                setSelected((prevState) =>
                  prevState.includes(option)
                    ? prevState.filter((item) => item !== option)
                    : [...prevState, option],
                );
              }}>
              <Checkbox
                disabled={option === location}
                checked={[...selected, location].includes(option)}
              />
              <Text>{LOCATION_TEXT[option] ?? option}</Text>
            </Pressable>
          ))}
        </View>
        <Divider />
        <View style={[sy['py-4'], sy['px-6']]}>
          <Text>
            Keuzes worden overgenomen voor andere geselecteerde banden
          </Text>
        </View>
      </Dialog>
    </>
  );
};

export default Linking;
