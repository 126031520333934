import React, {useEffect, useState, useCallback} from 'react';
import {View, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';

import {
  service_request as serviceRequestApi,
  rob as robApi,
  fleet as fleetApi,
} from '~/api/private';
import Format from '~/lib/format';

import sy from '~/styles';
import {
  Text,
  RadioButton,
  Link,
  Pressable,
  Dialog,
  TireTooltip,
  ActionButton,
} from '~/components/controls';
import Actionsheet, {Title, Actions} from '~/components/controls/Actionsheet';
import {AddTireModal} from '~/components/shared/Modals';
import {
  ROB_TIRE_SWAP_CODE,
  ROB_TIRE_REPLACE_CODE,
  ROB_TIRE_REPAIR_CODE,
  TIRE_STATE,
  TIRE_STATE_LABEL,
  TIRE_SOURCE,
  TIRE_SOURCE_LABEL,
  ROB_TIRE_CODES,
  LOCATION_TEXT,
  TIRE_REPAIR,
  TIRE_SWAP_SEASON_INDICATOR,
} from '~/types/rob';
import {useDispatch} from '~/lib/hooks';

import ListIcon from '~/images/md-icons/list/materialicons/24px.svg';
import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';
import ArchiveIcon from '~/images/md-icons/archive/materialicons/24px.svg';
import TireRepairIcon from '~/images/md-icons/tire_repair/materialicons/24px.svg';
import HandshakeIcon from '~/images/md-icons/handshake/materialicons/24px.svg';
import ReceiptLongIcon from '~/images/md-icons/receipt_long/materialicons/24px.svg';

import {SeasonIndicator, Profile, Linking} from '../shared';

const SCREEN = {
  Task: 'Task',
  SLA: 'SLA',
  Reason: 'Reason',
  Source: 'Source',
  StoredReason: 'StoredReason',
};

const UNMOUNT_ACTION = {
  None: 'None',
  Remove: 'Remove',
  Store: 'Store',
  Repair: 'Repair',
};

const Unmount_Actions = [
  {
    key: UNMOUNT_ACTION.None,
    label: 'Geen actie',
    icon: <ListIcon />,
  },
  {
    key: UNMOUNT_ACTION.Remove,
    label: 'Afvoeren',
    icon: <DeleteIcon />,
    sla_items: [
      {
        rob_code: ROB_TIRE_REPLACE_CODE,
        default_source: TIRE_SOURCE.New,
        default_tire_state: TIRE_STATE.Worn,
        label: 'Bandvervanging',
        action: 'Current_Replace',
      },
      {
        rob_code: ROB_TIRE_SWAP_CODE,
        default_source: TIRE_SOURCE.New,
        default_stored_source: TIRE_SOURCE.Stored,
        default_stored_tire_state: TIRE_STATE.Worn,
        label: 'Wissel zonder velg',
        action: 'Current_Remove',
      },
    ],
  },
  {
    key: UNMOUNT_ACTION.Store,
    label: 'Opslaan',
    icon: <ArchiveIcon />,
    sla_items: [
      {
        rob_code: ROB_TIRE_SWAP_CODE,
        default_source: TIRE_SOURCE.New,
        default_stored_source: TIRE_SOURCE.Stored,
        default_stored_tire_state: TIRE_STATE.Worn,
        label: 'Wissel zonder velg',
        action: 'Current_Store',
      },
      {
        rob_code: ROB_TIRE_SWAP_CODE,
        default_source: TIRE_SOURCE.New,
        default_stored_source: TIRE_SOURCE.Stored,
        default_stored_tire_state: TIRE_STATE.Worn,
        label: 'Wissel met velg',
        action: 'Current_StoreWheel',
        is_wheel: true,
      },
    ],
  },
  {
    key: UNMOUNT_ACTION.Repair,
    label: 'Bewerken',
    icon: <TireRepairIcon />,
    sla_items: [
      {
        rob_code: ROB_TIRE_REPAIR_CODE,
        label: 'Repareren',
        action: TIRE_REPAIR.Repareren,
        operation_code: '03',
      },
      {
        rob_code: ROB_TIRE_REPAIR_CODE,
        label: 'Balanceren',
        action: TIRE_REPAIR.Balanceren,
        operation_code: '20',
      },
      {
        rob_code: ROB_TIRE_REPAIR_CODE,
        label: 'Omwisselen',
        action: TIRE_REPAIR.Wielomwisselen,
        operation_code: '28',
      },
    ],
  },
];

const Tire_Sources = {
  [ROB_TIRE_REPLACE_CODE]: [
    TIRE_SOURCE.New,
    TIRE_SOURCE.Reserve,
    TIRE_SOURCE.Used,
    TIRE_SOURCE.LessorStock,
    TIRE_SOURCE.ThirdParty,
    TIRE_SOURCE.Private,
  ],
  [ROB_TIRE_SWAP_CODE]: [
    TIRE_SOURCE.Stored,
    TIRE_SOURCE.New,
    TIRE_SOURCE.Reserve,
    TIRE_SOURCE.Used,
    TIRE_SOURCE.LessorStock,
    TIRE_SOURCE.ThirdParty,
  ],
};

const NEW_TIRE_INITIAL_PROFILE_WIDTH = 8;

const SlaPrice = ({action, sla, style}) => {
  let price = sla.find(
    (item) => item.rob_code === action.rob_code && item.action === null,
  )?.price;

  const sla_item = sla.find(
    (item) =>
      item.rob_code === action.rob_code && item.action === action.action,
  );
  if (sla_item) {
    price = sla_item.price;
  }

  return <Text style={style}>{Format.price(price)}</Text>;
};

const TIRE_COST = {
  service: 'service',
  disposalFee: 'disposalFee',
  surchargeRunOnFlat: 'surchargeRunOnFlat',
  storageCosts: 'storageCosts',
  tire_price: 'tire_price',
  surchargeTireSource: 'surchargeTireSource',
};

const COST_LABEL = {
  [TIRE_COST.service]: 'Servicetarief',
  [TIRE_COST.disposalFee]: 'Verwijderingsbijdrage',
  [TIRE_COST.surchargeRunOnFlat]: 'ROF toeslag',
  [TIRE_COST.storageCosts]: 'Opslagkosten',
  [TIRE_COST.tire_price]: 'Bandprijs',
  [TIRE_COST.surchargeTireSource]: 'Toeslag uit voorraad LM',
};

const MountedTire = ({
  mounted,
  location,
  linked,
  setMounted,
  setShowAddTireModal,
}) => (
  <View style={[sy['flex-row']]}>
    <View style={[sy['p-4'], sy['pr-4']]}>
      <Profile
        location={location}
        widths={[location, ...(linked ?? [])].reduce((acc, key) => {
          acc[key] = mounted[key].profile_width;
          return acc;
        }, {})}
        onChange={(values) =>
          setMounted((prevState) => {
            const new_state = {...prevState};

            Object.keys(values).forEach((item) => {
              new_state[item].profile_width = values[item];
            });

            return new_state;
          })
        }
      />
    </View>
    <View style={[sy['flex-1']]}>
      <View
        style={[
          sy['flex-row'],
          sy['flex-1'],
          sy['justify-between'],
          sy['py-4'],
          sy['pr-4'],
        ]}>
        <View style={[sy['flex-1']]}>
          <>
            <Text style={sy.truncate}>
              {mounted[location].rob_tire_id ? (
                mounted[location].title
              ) : (
                <>Onbekend</>
              )}
            </Text>
            <Link
              textStyle={[sy.small, sy['text-lightgray'], sy.truncate]}
              onPress={() =>
                setShowAddTireModal({
                  rob_tire_id: mounted[location].rob_tire_id,
                  all_tires_allowed: true,
                  onSelected: (selected_tire) =>
                    setMounted((prevState) => {
                      const new_state = {...prevState};

                      new_state[location] = {
                        ...new_state[location],
                        rob_tire_id: selected_tire.id,
                        title: `${selected_tire.make} ${selected_tire.modelTypeProfile} ${selected_tire.typeNumber}`,
                        description: selected_tire.description,
                        category: selected_tire.category,
                        seasonIndicator: selected_tire.seasonIndicator,
                      };

                      return new_state;
                    }),
                })
              }>
              {mounted[location].rob_tire_id ? (
                mounted[location].description
              ) : (
                <>Keuze maken</>
              )}
            </Link>
          </>
        </View>
        <SeasonIndicator indicator={mounted[location].seasonIndicator} />
      </View>
    </View>
  </View>
);

const StoredTire = ({
  stored,
  location,
  linked,
  setStored,
  setShowAddTireModal,
}) => (
  <View style={[sy['flex-row']]}>
    <View style={[sy['p-4'], sy['pr-4']]}>
      <Profile
        location={location}
        widths={[location, ...(linked ?? [])].reduce((acc, key) => {
          acc[key] = stored[key].profile_width;
          return acc;
        }, {})}
        onChange={(values) => {
          setStored((prevState) => {
            const newState = {...prevState};

            Object.keys(values).forEach((item) => {
              newState[item].profile_width = values[item];
            });

            return newState;
          });
        }}
      />
    </View>
    <View style={[sy['flex-1']]}>
      <View
        style={[
          sy['flex-row'],
          sy['flex-1'],
          sy['justify-between'],
          sy['py-4'],
          sy['pr-4'],
        ]}>
        <View style={[sy['flex-1']]}>
          <>
            <Text style={sy.truncate}>
              {stored[location]?.rob_tire_id ? (
                stored[location].title
              ) : (
                <>Onbekend</>
              )}
            </Text>
            <Link
              textStyle={[sy.small, sy['text-lightgray'], sy.truncate]}
              onPress={() =>
                setShowAddTireModal({
                  rob_tire_id: stored[location]?.rob_tire_id,
                  all_tires_allowed: false,
                  onSelected: (selected_tire) =>
                    setStored((prevState) => ({
                      ...prevState,
                      [location]: {
                        ...(prevState[location] ?? {}),
                        rob_tire_id: selected_tire.id,
                        title: `${selected_tire.make} ${selected_tire.modelTypeProfile} ${selected_tire.typeNumber}`,
                        description: selected_tire.description,
                        category: selected_tire.category,
                        seasonIndicator: selected_tire.seasonIndicator,
                      },
                    })),
                })
              }>
              {stored[location]?.rob_tire_id ? (
                stored[location].description
              ) : (
                <>Keuze maken</>
              )}
            </Link>
          </>
        </View>
        <SeasonIndicator indicator={stored[location]?.seasonIndicator} />
      </View>
    </View>
  </View>
);

const NewTire = ({
  newTire,
  mounted,
  stored,
  location,
  tireAction,
  mountedTireSource,
  lessor,
  brands,
  setNewTire,
  setShowAddTireModal,
}) => (
  <View style={[sy['flex-row']]}>
    <View style={[sy['p-4'], sy['pr-4']]}>
      <Profile
        location={location}
        widths={{
          [location]: newTire.profile_width,
        }}
        onChange={(values) =>
          setNewTire((prevState) => ({
            ...prevState,
            profile_width: values[location],
          }))
        }
      />
    </View>
    <View style={[sy['flex-1']]}>
      <View
        style={[
          sy['flex-row'],
          sy['flex-1'],
          sy['justify-between'],
          sy['py-4'],
          sy['pr-4'],
        ]}>
        <View style={[sy['flex-1']]}>
          <>
            <Text style={sy.truncate}>
              {newTire.rob_tire_id ? newTire.title : <>Onbekend</>}
            </Text>
            <Link
              textStyle={[sy.small, sy['text-lightgray'], sy.truncate]}
              onPress={() =>
                setShowAddTireModal({
                  rob_tire_id:
                    newTire.rob_tire_id ??
                    tireAction.rob_code === ROB_TIRE_SWAP_CODE
                      ? stored[location]?.rob_tire_id
                      : mounted[location]?.rob_tire_id,
                  all_tires_allowed:
                    tireAction.rob_code === ROB_TIRE_REPLACE_CODE,
                  initial_state: {
                    seasonIndicators:
                      tireAction.rob_code === ROB_TIRE_SWAP_CODE
                        ? [
                            ...TIRE_SWAP_SEASON_INDICATOR[
                              mounted[location]?.seasonIndicator
                            ],
                          ]
                        : null,
                  },
                  onSelected: (selected_tire) =>
                    setNewTire((prevState) => ({
                      ...prevState,
                      rob_tire_id: selected_tire.id,
                      title: `${selected_tire.make} ${selected_tire.modelTypeProfile} ${selected_tire.typeNumber}`,
                      description: selected_tire.description,
                      category: selected_tire.category,
                      makeCode: selected_tire.makeCode,
                      eanCode: selected_tire.eanCode,
                      manufacturerSuggestedRetailPrice:
                        selected_tire.manufacturerSuggestedRetailPrice,
                      seasonIndicator: selected_tire.seasonIndicator,
                      profile_width:
                        prevState.profile_width ??
                        NEW_TIRE_INITIAL_PROFILE_WIDTH,
                      ...([
                        TIRE_SOURCE.Reserve,
                        TIRE_SOURCE.LessorStock,
                        TIRE_SOURCE.ThirdParty,
                        TIRE_SOURCE.Private,
                      ].includes(mountedTireSource)
                        ? {
                            price: 0,
                            discount: 0,
                          }
                        : {
                            price:
                              selected_tire.manufacturerSuggestedRetailPrice,
                            discount:
                              Math.round(
                                (1 -
                                  (brands[selected_tire.makeCode]?.discount ??
                                    1)) *
                                  selected_tire.manufacturerSuggestedRetailPrice *
                                  100,
                              ) / 100,
                          }),
                    })),
                })
              }>
              {newTire.rob_tire_id ? newTire.description : <>Keuze maken</>}
            </Link>
          </>
        </View>
        {newTire.rob_tire_id ? (
          <View style={[sy['pl-2']]}>
            <Text style={sy.smallRegular}>
              {newTire.price > 0
                ? Format.price(newTire.price - newTire.discount)
                : null}
            </Text>
            <View style={[sy['flex-row'], sy['justify-end']]}>
              {newTire.price > 0 ? (
                <TireTooltip
                  lessor={lessor}
                  {...newTire}
                  {...(brands[newTire.makeCode] ?? {})}
                />
              ) : null}
              <SeasonIndicator indicator={newTire.seasonIndicator} />
            </View>
          </View>
        ) : null}
      </View>
    </View>
  </View>
);

const ActionsActionsheet = (props) => {
  const dispatch = useDispatch();

  const {
    request_id,
    is_fleet_car,
    is_retail_car,
    license,
    selected,
    onDismiss,
    onChange,
  } = props;

  const [loading, setLoading] = useState(true);
  const [screen, setScreen] = useState(SCREEN.Task);
  const [lessor, setLessor] = useState(null);
  const [sla, setSLA] = useState(null);
  const [brands, setBrands] = useState(null);
  const [robComponents, setRobComponents] = useState(null);
  const [unmountAction, setUnmountAction] = useState(UNMOUNT_ACTION.None);
  const [tireAction, _setTireAction] = useState(null);
  const [tireState, setTireState] = useState(null);
  const [storedTireState, setStoredTireState] = useState(null);
  const [mountedTireSource, _setMountedTireSource] = useState(null);
  const [mounted, setMounted] = useState({});
  const [stored, setStored] = useState({});
  const [newTire, setNewTire] = useState(null);
  const [showAddTireModal, setShowAddTireModal] = useState(null);
  const [preview, setPreview] = useState(null);
  const [linked, setLinked] = useState(null);
  const [fleetPermissions, setFleetPermissions] = useState(null);

  const [showCostsPreviewDialog, setShowCostsPreviewDialog] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.get(request_id, dispatch);
      const {result: fleet_contract} = await fleetApi.contract(
        result[0].fleet_contract_id,
      );

      const {permissions} = fleet_contract.contract;
      setFleetPermissions({
        [ROB_TIRE_REPAIR_CODE]: permissions?.tires?.repair,
        [ROB_TIRE_REPLACE_CODE]: permissions?.tires?.replace,
        [ROB_TIRE_SWAP_CODE]: permissions?.tires?.swap,
      });
    };

    if (is_fleet_car) {
      fetch();
    }
  }, [request_id, is_fleet_car]);

  const setTireAction = (action) => {
    _setTireAction((prevState) => {
      if (prevState !== action) {
        setTireState(action?.default_tire_state);
        setStoredTireState(action?.default_stored_tire_state);
        if (
          action?.default_stored_source &&
          stored[props.location]?.rob_tire_id
        ) {
          setMountedTireSource(action.default_stored_source);
        } else {
          setMountedTireSource(action?.default_source);
        }
      }

      return action;
    });
  };

  const setMountedTireSource = (source) => {
    _setMountedTireSource((prevState) => {
      if (prevState !== source) {
        setNewTire(!source || source === TIRE_SOURCE.Stored ? null : {});
      }

      return source;
    });
  };

  useEffect(() => {
    const location = props.location;

    const find_action = (action) => {
      if (!action) {
        return null;
      }

      return Unmount_Actions.map((item) => {
        const sla_item = item.sla_items?.find(
          (sla_item) => sla_item.action === action,
        );

        if (sla_item) {
          return {
            key: item.key,
            tireAction: sla_item,
          };
        }

        return null;
      })
        .filter(Boolean)
        .shift();
    };

    const unmount_action =
      find_action(selected[location].repairs?.[0]) ??
      find_action(selected[location].action);

    if (unmount_action) {
      setUnmountAction(unmount_action.key);
      _setTireAction(unmount_action.tireAction);
    }

    setTireState(
      selected[location].attributes?.tire_state ??
        unmount_action?.tireAction?.default_tire_state,
    );

    setStoredTireState(
      selected[location].stored.attributes?.tire_state ?? TIRE_STATE.Worn,
    );

    _setMountedTireSource(
      (selected[location].new_tire
        ? selected[location].new_tire.source ?? TIRE_SOURCE.New
        : null) ??
        (unmount_action?.tireAction?.default_stored_source &&
        selected[location]?.stored?.attributes?.rob_tire_id
          ? unmount_action?.tireAction?.default_stored_source
          : unmount_action?.tireAction?.default_source),
    );

    setMounted(
      Object.keys(selected).reduce((acc, item) => {
        acc[item] = {
          ...(selected[item]?.attributes ?? {}),
        };
        return acc;
      }, {}),
    );

    setStored(
      Object.keys(selected).reduce((acc, item) => {
        acc[item] = {
          ...(selected[item]?.stored?.attributes ?? {}),
        };
        return acc;
      }, {}),
    );

    setNewTire(selected[location]?.new_tire);

    const fetch = async () => {
      const {result: sla} = await serviceRequestApi.sla(request_id);
      setLessor(sla.lessor);
      setSLA(sla.tires.codes);
      setBrands(
        sla.tires.brands.reduce((acc, item) => {
          acc[item.makeCode] = {
            discount: item.discount,
            order: item.order,
          };
          return acc;
        }, {}) ?? {},
      );

      if (!is_fleet_car) {
        const {result: rob_components} = await robApi.components(
          license,
          request_id,
          ROB_TIRE_CODES,
        );

        setRobComponents(rob_components);
      }

      setLoading(false);
    };

    fetch();
  }, [license, request_id, props.location, fleetPermissions, selected]);

  const processChanges = useCallback(() => {
    const locations = [props.location, ...(linked ?? [])];
    const changes = locations.reduce((acc, location) => {
      const stored_tire = {
        // This is intentional so first take the attributes of the stored tire according to the tire of the currently selected location.
        // Then overwrite these attributes with the location specific stored tire attributes.
        ...stored[props.location],
        ...stored[location],
        tire_state:
          tireAction?.rob_code === ROB_TIRE_SWAP_CODE ? storedTireState : null,
      };

      let stored_action = null;
      if (tireAction?.rob_code === ROB_TIRE_SWAP_CODE) {
        if (mountedTireSource === TIRE_SOURCE.Stored) {
          stored_action = tireAction.is_wheel
            ? 'Stored_WheelChange'
            : 'Stored_TireChange';
        } else {
          stored_action = stored_tire.rob_tire_id ? 'Stored_ReplaceNew' : null;
        }
      }

      acc[location] = {
        rob_code: tireAction?.rob_code,
        action:
          tireAction?.rob_code === ROB_TIRE_REPAIR_CODE
            ? 'Current_TireRepair'
            : tireAction?.action,
        repairs:
          tireAction?.rob_code === ROB_TIRE_REPAIR_CODE
            ? [tireAction.action]
            : null,
        attributes: {
          ...(mounted[location]?.rob_tire_id == null
            ? mounted[props.location]
            : mounted[location]),
          tire_state: tireState,
        },
        stored: {
          action: stored_action,
          attributes: {
            ...stored_tire,
          },
        },
        new_tire:
          mountedTireSource && mountedTireSource !== TIRE_SOURCE.Stored
            ? {
                ...newTire,
                source: mountedTireSource,
              }
            : null,
      };

      return acc;
    }, {});

    return {
      ...changes,
    };
  }, [
    linked,
    tireAction,
    mounted,
    newTire,
    mountedTireSource,
    storedTireState,
    tireState,
    stored,
    props.location,
  ]);

  const onOK = async () => {
    const changes = processChanges();
    onChange(changes);
  };

  useEffect(() => {
    setPreview(null);
    if (tireAction?.rob_code) {
      const fetch = async () => {
        const changes = processChanges();
        const {success, result} = await serviceRequestApi.preview_tires(
          request_id,
          {
            [props.location]: changes[props.location],
          },
        );

        if (success) {
          setPreview(result);
        }
      };

      fetch();
    }
  }, [request_id, unmountAction, tireAction, props.location, processChanges]);

  if (screen === SCREEN.Task) {
    const amount = preview?.reduce((acc, item) => acc + item.amount, 0);

    const part_preview = preview?.reduce((acc, item) => {
      if (item.parts?.[props.location]) {
        for (const cost in item.parts[props.location]) {
          if (!acc[cost]) {
            acc[cost] = 0;
          }

          acc[cost] += item.parts[props.location][cost];
        }
      }

      return acc;
    }, {});

    let part_costs = 0;
    if (part_preview) {
      part_costs =
        Object.values(part_preview).reduce((acc, amount) => acc + amount, 0) -
        part_preview.service -
        (part_preview.tire_price ?? 0);
    }

    return (
      <>
        <Actionsheet visible={true} dismissable={false} onDismiss={onDismiss}>
          {loading && (
            <View style={[sy['p-4']]}>
              <ActivityIndicator size="large" color="#231fda" />
            </View>
          )}
          {!loading && (
            <>
              <Title>
                <View style={[sy['flex-row'], sy['items-center']]}>
                  {props.location.startsWith('X') ? null : (
                    <Linking
                      value={linked}
                      location={props.location}
                      options={Object.keys(selected)}
                      onChange={setLinked}
                    />
                  )}
                  <Text style={[sy.large, {fontSize: 20}]}>
                    Band&nbsp;
                    {(
                      LOCATION_TEXT[props.location] ?? props.location
                    ).toLowerCase()}
                  </Text>
                </View>
              </Title>
              <Divider />
              <MountedTire
                mounted={mounted}
                location={props.location}
                linked={linked}
                setMounted={setMounted}
                setShowAddTireModal={setShowAddTireModal}
              />
              <Divider />
              {mounted[props.location].rob_tire_id && (
                <>
                  {Unmount_Actions.map((action, index, array) => {
                    if (
                      action.sla_items?.every((item) =>
                        is_retail_car
                          ? false
                          : fleetPermissions
                          ? !fleetPermissions[item.rob_code]
                          : !robComponents?.[item.rob_code],
                      )
                    ) {
                      return null;
                    }

                    return (
                      <View key={action.key} style={[sy['flex-row']]}>
                        <Pressable
                          style={[sy['p-4']]}
                          onPress={() => {
                            if (unmountAction !== action.key) {
                              setUnmountAction(action.key);
                              const sla_item = action.sla_items?.filter(
                                (item) =>
                                  is_retail_car
                                    ? true
                                    : fleetPermissions
                                    ? fleetPermissions[item.rob_code]
                                    : robComponents[item.rob_code],
                              )?.[0];
                              setTireAction(sla_item);
                              setMountedTireSource(sla_item?.default_source);
                            }
                          }}>
                          <RadioButton checked={action.key === unmountAction} />
                        </Pressable>
                        <View style={[sy['flex-1'], sy['ml-4']]}>
                          <View
                            style={[
                              sy['flex-1'],
                              sy['flex-row'],
                              sy['py-4'],
                              sy['pr-4'],
                              sy['justify-between'],
                            ]}>
                            <View>
                              <Text>{action.label}</Text>
                              {action.key === unmountAction && tireAction && (
                                <Pressable
                                  onPress={() => setScreen(SCREEN.SLA)}>
                                  <Text
                                    style={[
                                      sy.small,
                                      sy['text-lightgray'],
                                      sy.underline,
                                    ]}>
                                    SLA&nbsp;&middot;&nbsp;
                                    {tireAction.label}
                                  </Text>
                                </Pressable>
                              )}
                            </View>
                            {action.key === unmountAction && tireAction ? (
                              part_preview?.service ? (
                                <Text style={[sy.smallRegular]}>
                                  {Format.price(part_preview.service)}
                                </Text>
                              ) : (
                                <SlaPrice
                                  style={[sy.smallRegular]}
                                  action={tireAction}
                                  sla={sla}
                                />
                              )
                            ) : (
                              <>{action.icon}</>
                            )}
                          </View>
                          {index < array.length - 1 && (
                            <Divider style={[{backgroundColor: '#f2f2f2'}]} />
                          )}
                        </View>
                      </View>
                    );
                  })}
                  <Divider />
                </>
              )}
              {tireAction?.rob_code && (
                <>
                  {tireAction.rob_code === ROB_TIRE_REPLACE_CODE && (
                    <>
                      <View
                        style={[
                          sy['p-4'],
                          sy['flex-row'],
                          sy['justify-between'],
                          sy['items-center'],
                        ]}>
                        <Text style={[sy.mediumBold]}>Reden</Text>
                        <Link onPress={() => setScreen(SCREEN.Reason)}>
                          {TIRE_STATE_LABEL[tireState]}
                        </Link>
                      </View>
                      <Divider />
                    </>
                  )}
                  {mountedTireSource && (
                    <>
                      <View
                        style={[
                          sy['p-4'],
                          sy['flex-row'],
                          sy['justify-between'],
                          sy['items-center'],
                        ]}>
                        <Text style={[sy.mediumBold]}>Artikel</Text>
                        <Link onPress={() => setScreen(SCREEN.Source)}>
                          {TIRE_SOURCE_LABEL[mountedTireSource]}
                        </Link>
                      </View>
                      {mountedTireSource === TIRE_SOURCE.Stored ? (
                        <>
                          <Divider style={[{backgroundColor: '#f2f2f2'}]} />
                          <StoredTire
                            stored={stored}
                            location={props.location}
                            linked={linked}
                            setStored={setStored}
                            setShowAddTireModal={setShowAddTireModal}
                          />
                        </>
                      ) : (
                        <>
                          {newTire && (
                            <>
                              <Divider style={[{backgroundColor: '#f2f2f2'}]} />
                              <NewTire
                                newTire={newTire}
                                mounted={mounted}
                                stored={stored}
                                location={props.location}
                                tireAction={tireAction}
                                mountedTireSource={mountedTireSource}
                                lessor={lessor}
                                brands={brands}
                                setNewTire={setNewTire}
                                setShowAddTireModal={setShowAddTireModal}
                              />
                            </>
                          )}
                        </>
                      )}
                      <Divider />
                    </>
                  )}
                  {stored[props.location]?.rob_tire_id &&
                    mountedTireSource !== TIRE_SOURCE.Stored &&
                    tireAction.rob_code === ROB_TIRE_SWAP_CODE && (
                      <>
                        <View
                          style={[
                            sy['p-4'],
                            sy['flex-row'],
                            sy['justify-between'],
                            sy['items-center'],
                          ]}>
                          <Text style={[sy.mediumBold]}>Opslag afvoeren</Text>
                          <Link onPress={() => setScreen(SCREEN.StoredReason)}>
                            {TIRE_STATE_LABEL[storedTireState]}
                          </Link>
                        </View>
                        <>
                          <Divider style={[{backgroundColor: '#f2f2f2'}]} />
                          <StoredTire
                            stored={stored}
                            location={props.location}
                            linked={linked}
                            setStored={setStored}
                            setShowAddTireModal={setShowAddTireModal}
                          />
                        </>
                        <Divider />
                      </>
                    )}
                  {part_preview && part_costs > 0 && (
                    <>
                      <View
                        style={[
                          sy['p-4'],
                          sy['flex-row'],
                          sy['justify-between'],
                          sy['items-center'],
                        ]}>
                        <Text style={[sy.mediumBold]}>Overige kosten</Text>
                        <Text style={[sy.smallRegular]}>
                          {Format.price(part_costs)}
                        </Text>
                      </View>
                      <Divider />
                    </>
                  )}
                  {!is_fleet_car && !is_retail_car && tireAction && (
                    <>
                      <View
                        style={[
                          sy['p-4'],
                          sy['flex-row'],
                          sy['justify-between'],
                          sy['items-center'],
                        ]}>
                        <Text style={[sy.mediumBold]}>ROB activiteit</Text>
                        <Text>
                          {tireAction.rob_code}&nbsp;&middot;&nbsp;
                          {robComponents[tireAction.rob_code]?.description}
                        </Text>
                      </View>
                      <Divider />
                    </>
                  )}
                </>
              )}
              <Actions style={[sy['justify-between']]}>
                <View style={[sy['flex-row'], sy['items-center']]}>
                  <Text style={[sy.mediumBold, sy['px-2']]}>
                    {amount ? Format.price(amount) : null}
                  </Text>
                  {amount > 0 && part_preview && (
                    <Pressable onPress={() => setShowCostsPreviewDialog(true)}>
                      <ReceiptLongIcon fill="#4a4a49" />
                    </Pressable>
                  )}
                </View>
                <View style={[sy['flex-row'], sy['gap-2']]}>
                  <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
                  <ActionButton onPress={onOK}>OK</ActionButton>
                </View>
              </Actions>
            </>
          )}
        </Actionsheet>

        <Dialog
          visible={showCostsPreviewDialog}
          title={<Text style={sy.largePlus}>Details</Text>}
          titleIcon={<ReceiptLongIcon fill="#4a4a49" />}
          onDismiss={() => setShowCostsPreviewDialog(false)}>
          <View style={[sy['py-4'], sy['gap-4']]}>
            {Object.keys(part_preview ?? {})
              .sort((a, b) => {
                if (a === 'tire_price') {
                  return -1;
                }

                if (b === 'tire_price') {
                  return 1;
                }

                const label_a = COST_LABEL[a] ?? a;
                const label_b = COST_LABEL[b] ?? b;

                return label_a.localeCompare(label_b);
              })
              .map((key) => (
                <View key={key} style={[sy['flex-row'], sy['justify-between']]}>
                  <Text>
                    {key === 'tire_price' && newTire
                      ? newTire.title
                      : COST_LABEL[key] ?? key}
                  </Text>
                  <Text style={[sy.smallRegular]}>
                    {Format.price(part_preview[key])}
                  </Text>
                </View>
              ))}
          </View>
        </Dialog>

        {showAddTireModal && (
          <AddTireModal
            license={license}
            request_id={request_id}
            rob_tire_id={showAddTireModal.rob_tire_id}
            initial_state={showAddTireModal.initial_state}
            onDismiss={() => setShowAddTireModal(false)}
            onChange={showAddTireModal.onSelected}
            all_tires_allowed={showAddTireModal.all_tires_allowed}
          />
        )}
      </>
    );
  }

  if (screen === SCREEN.SLA) {
    const action = Unmount_Actions.find(
      (action) => action.key === unmountAction,
    );

    const collect_allowed_repair_codes = (rob_component) => {
      return rob_component.operations[0].subcomponents[0].subcomponents
        .filter((item) => item.operation)
        .map((item) => item.operation.code);
    };

    return (
      <Actionsheet visible={true} onDismiss={onDismiss}>
        <Title style={[{display: 'flex'}, sy['items-center']]}>
          <View style={[{paddingRight: 30}]}>
            <HandshakeIcon fill="#4a4a49" />
          </View>
          <Text style={[sy.large, {fontSize: 20, lineHeight: 30}]}>
            SLA items &middot; {action.label}
          </Text>
        </Title>
        <Divider />
        {action.sla_items.map((item, index, array) => {
          let is_allowed = is_retail_car
            ? true
            : fleetPermissions
            ? fleetPermissions[item.rob_code]
            : robComponents[item.rob_code];

          if (is_allowed && item.rob_code === ROB_TIRE_REPAIR_CODE) {
            is_allowed = is_fleet_car
              ? true
              : collect_allowed_repair_codes(
                  robComponents[item.rob_code],
                ).includes(item.operation_code);
          }

          if (!is_allowed) {
            return null;
          }

          return (
            <Pressable
              key={item.action}
              style={[sy['flex-row']]}
              onPress={() => setTireAction(item)}>
              <View style={[sy['p-4']]}>
                <RadioButton checked={item.action === tireAction.action} />
              </View>
              <View style={[sy['flex-1'], sy['ml-4']]}>
                <View
                  style={[
                    sy['flex-1'],
                    sy['flex-row'],
                    sy['py-4'],
                    sy['pr-4'],
                    sy['justify-between'],
                    sy['items-center'],
                  ]}>
                  <Text>{item.label}</Text>
                  <SlaPrice style={[sy.smallRegular]} action={item} sla={sla} />
                </View>
                {index < array.length - 1 && (
                  <Divider style={[{backgroundColor: '#f2f2f2'}]} />
                )}
              </View>
            </Pressable>
          );
        })}
        <Divider />
        <Actions>
          <ActionButton onPress={() => setScreen(SCREEN.Task)}>OK</ActionButton>
        </Actions>
      </Actionsheet>
    );
  }

  if (screen === SCREEN.Reason) {
    return (
      <Actionsheet visible={true} onDismiss={onDismiss}>
        <Title style={[{display: 'flex'}, sy['items-center']]}>
          Reden afvoeren
        </Title>
        <Divider />
        {[
          TIRE_STATE.Worn,
          TIRE_STATE.Punctured,
          TIRE_STATE.Loud,
          TIRE_STATE.Torn,
          TIRE_STATE.DriedOut,
        ].map((item, index, array) => (
          <Pressable
            key={item}
            style={[sy['flex-row']]}
            onPress={() => setTireState(item)}>
            <View style={[sy['p-4']]}>
              <RadioButton checked={tireState === item} />
            </View>
            <View style={[sy['flex-1'], sy['ml-4']]}>
              <View
                style={[sy['flex-1'], sy['flex-row'], sy['py-4'], sy['pr-4']]}>
                <Text>{TIRE_STATE_LABEL[item]}</Text>
              </View>
              {index < array.length - 1 && (
                <Divider style={[{backgroundColor: '#f2f2f2'}]} />
              )}
            </View>
          </Pressable>
        ))}
        <Divider />
        <Actions>
          <ActionButton onPress={() => setScreen(SCREEN.Task)}>OK</ActionButton>
        </Actions>
      </Actionsheet>
    );
  }

  if (screen === SCREEN.Source) {
    return (
      <Actionsheet visible={true} onDismiss={onDismiss}>
        <Title style={[{display: 'flex'}, sy['items-center']]}>
          Herkomst artikel
        </Title>
        <Divider />
        {Tire_Sources[tireAction?.rob_code].map((item, index, array) => (
          <Pressable
            key={item}
            style={[sy['flex-row']]}
            onPress={() => setMountedTireSource(item)}>
            <View style={[sy['p-4']]}>
              <RadioButton checked={mountedTireSource === item} />
            </View>
            <View style={[sy['flex-1'], sy['ml-4']]}>
              <View
                style={[sy['flex-1'], sy['flex-row'], sy['py-4'], sy['pr-4']]}>
                <Text>{TIRE_SOURCE_LABEL[item]}</Text>
              </View>
              {index < array.length - 1 && (
                <Divider style={[{backgroundColor: '#f2f2f2'}]} />
              )}
            </View>
          </Pressable>
        ))}
        <Divider />
        <Actions>
          <ActionButton onPress={() => setScreen(SCREEN.Task)}>OK</ActionButton>
        </Actions>
      </Actionsheet>
    );
  }

  if (screen === SCREEN.StoredReason) {
    return (
      <Actionsheet visible={true} onDismiss={onDismiss}>
        <Title style={[{display: 'flex'}, sy['items-center']]}>
          Reden afvoeren
        </Title>
        <Divider />
        {[
          TIRE_STATE.Worn,
          TIRE_STATE.Punctured,
          TIRE_STATE.Loud,
          TIRE_STATE.Torn,
          TIRE_STATE.DriedOut,
        ].map((item, index, array) => (
          <Pressable
            key={item}
            style={[sy['flex-row']]}
            onPress={() => setStoredTireState(item)}>
            <View style={[sy['p-4']]}>
              <RadioButton checked={storedTireState === item} />
            </View>
            <View style={[sy['flex-1'], sy['ml-4']]}>
              <View
                style={[sy['flex-1'], sy['flex-row'], sy['py-4'], sy['pr-4']]}>
                <Text>{TIRE_STATE_LABEL[item]}</Text>
              </View>
              {index < array.length - 1 && (
                <Divider style={[{backgroundColor: '#f2f2f2'}]} />
              )}
            </View>
          </Pressable>
        ))}
        <Divider />
        <Actions>
          <ActionButton onPress={() => setScreen(SCREEN.Task)}>OK</ActionButton>
        </Actions>
      </Actionsheet>
    );
  }
};

export default ActionsActionsheet;
